

import { Sidebar } from "react-pro-sidebar";
import { Link, useNavigate } from "react-router-dom";
// import { Img } from "./Img/index";
import { Text } from "./Text/index"
import snekoLogo from "../../assets/admin/image/snekoLogoTrans.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboardList, faUsers, faUtensils, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { Menu, MenuItem } from 'react-pro-sidebar';
import { useState } from "react";
import { useMediaQuery } from 'react-responsive'
import AdminStorage from "../../storage/AdminStorage";
const AdminSideBar = () => {
  const navigate = useNavigate()
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const handleToggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1024px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1023px)' })
  const pathname = window.location.pathname
  return (
    <>
      {isTabletOrMobile && !isSidebarOpen && <div className="lg:hidden">
        <button onClick={handleToggleSidebar} className="span text-_black p-4">
          ☰
        </button>
      </div>}
      <Sidebar width={isSidebarOpen && isTabletOrMobile ? '' : isDesktopOrLaptop ? "12%" : "0%"}>
        <Menu iconShape="square" className="bg-_white-150">
          <div style={{ display: "flex", flexDirection: 'column', justifyContent: "space-between", height: "100vh" }}>
            <div>
              <div
                style={{
                  padding: '5px',
                  whiteSpace: 'nowrap',
                }}
                className="flex justify-between"
              >
                <img src={snekoLogo} alt="logo" style={{ width: '50%' }} />
                {isTabletOrMobile && isSidebarOpen && <div className="lg:hidden">
                  <button onClick={handleToggleSidebar} className="span text-_black p-4">
                    ✕
                  </button>
                </div>}
              </div>

              <MenuItem className={pathname === "/adminOrder" ? "my-10 bg-_white-50" : " my-10 bg-_white-150 hover:bg-_black-100"} component={<Link to="/adminOrder" />}>
                <FontAwesomeIcon icon={faClipboardList} className="text-_blue mr-2.5 text-lg" />
                <Text className="w-auto" size={"poppinsRegularDecription"} >
                  Orders
                </Text>
              </MenuItem>
              <MenuItem className={pathname === "/adminRestaurant" || pathname === '/adminRestaurantDetail' || pathname === '/adminEditRestaurant' || pathname === "/adminAddRestaurant" ? "my-10 bg-_white-50" : " my-10 bg-_white-150 hover:bg-_black-100"} component={<Link to="/adminRestaurant" />} >
                <FontAwesomeIcon icon={faUtensils} className="text-_blue mr-2.5 text-lg" />
                <Text className="w-auto" size={"poppinsRegularDecription"} >
                  Restaurants
                </Text>
              </MenuItem>
              <MenuItem className={pathname === "/adminCustomer" ? "my-10 bg-_white-50" : " my-10 bg-_white-150 hover:bg-_black-100"} component={<Link to="/adminCustomer" />}>
                <FontAwesomeIcon icon={faUsers} className="text-_blue mr-2.5 text-lg" />
                <Text className="w-auto" size={"poppinsRegularDecription"} >
                  Customers
                </Text>
              </MenuItem>
            </div>

            <div onClick={() => {
              AdminStorage.Instance.Logout()
              navigate('/admin')
            }}>
              <MenuItem className="" >
                <FontAwesomeIcon icon={faSignOutAlt} className="text-_blue mr-2.5 text-lg" />
                <Text className="w-auto" size={"poppinsRegularDecription"} >
                  Logout
                </Text>
              </MenuItem>
            </div>
          </div>
        </Menu>
      </Sidebar>

    </>
  );
};

AdminSideBar.defaultProps = {};

export default AdminSideBar;