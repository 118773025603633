
class OtherStorage {
    slotTiming = ["1:00 PM", "8:30 PM"];
    pickUpPoint = ["IBS"];

    static get Instance() {
        if (!OtherStorage._instance) {
            OtherStorage._instance = new OtherStorage();
        }
        return OtherStorage._instance;
    }

}

export default OtherStorage;
