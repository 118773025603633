import React, { useState } from "react";
import { Avatar, Button, Empty, Select, Skeleton, Switch } from "antd";
import { useNavigate } from "react-router-dom";
import "../styles/admin/mainPage.css"
import { v4 as uuid } from "uuid";
import { faBell, faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Text } from "./admin/Text";
import restaurantSvg from "../assets/restaurant.svg"
const TableRenderer = (props) => {

    const navigate = useNavigate()


    const showComponent = (restaurantDetail) => {
        if (props.type === 'restaurant') {
            navigate('/adminRestaurantDetail', { state: { id: restaurantDetail.restaurant_id } });

        }
    }

    const CustomAvatar = ({ img, defaultImg, ...otherProps }) => {
        const [avatarSrc, setAvatarSrc] = useState(img);
        const handleError = () => {
            setAvatarSrc(defaultImg);
        };

        return <Avatar {...otherProps} src={avatarSrc} onError={handleError} />;
    };


    const rowRenderer = () => {
        return props.dataList.map((itemObj, index) => {
            return (
                <tr key={uuid()} className="bg-_white-100 border-b  hover:bg-_white-150">
                    <td className="px-6 py-4 font-medium text-_black-50 whitespace-nowrap ">
                        {index + 1}
                    </td>
                    {props.detailsToDisplay.map((item) => {

                        return (
                            <>
                                {item === "order_for" ? (
                                    <>
                                        <td className="px-6 py-4 font-medium text-_black-50 whitespace-nowrap">
                                            <Text size="poppinsRegularDescription">
                                                {itemObj.order_for?.name}
                                            </Text>
                                        </td>
                                        <td className="px-6 py-4 font-medium text-_black-50 whitespace-nowrap">
                                            <Text size="poppinsRegularDescription">
                                                {itemObj.order_for?.phone_number}
                                            </Text>

                                        </td>
                                    </>
                                ) : (
                                    <>
                                        {props.type !== "customers" ? (
                                            <>
                                                {item !== "paid" && item !== "operational" ? (
                                                    item === "restaurant_img_path" ? (
                                                        <td key={uuid()} className="px-4 py-4 font-medium text-_black-50 whitespace-nowrap">
                                                            <CustomAvatar
                                                                img={itemObj[item]}
                                                                defaultImg={restaurantSvg}
                                                                shape="square"
                                                                size={{ md: 16, lg: 16, xl: 24, xxl: 40 }}
                                                                className="mr-2"
                                                            />
                                                        </td>
                                                    ) : (
                                                        <td className="px-4 py-4 font-medium text-_black-50 whitespace-nowrap">
                                                            <Text size="poppinsRegularDescription">
                                                                {itemObj[item]}
                                                            </Text>
                                                        </td>
                                                    )
                                                ) : (
                                                    <>
                                                        {item === "paid" ?
                                                            (<td className="px-4 py-4 font-medium text-_black-50 whitespace-nowrap">

                                                                <Select
                                                                    defaultValue={itemObj[item]}
                                                                    style={{
                                                                        width: 100,
                                                                    }}
                                                                    onChange={(value) => {
                                                                        props.handleDropdown(value, itemObj.order_id, index)
                                                                    }}
                                                                    options={[
                                                                        {
                                                                            value: 'unPaid',
                                                                            label: 'UnPaid',
                                                                        },
                                                                        {
                                                                            value: 'paid',
                                                                            label: 'Paid',
                                                                        },
                                                                        {
                                                                            value: 'completed',
                                                                            label: 'Completed',
                                                                        },
                                                                    ]}
                                                                />
                                                            </td>) : (<td className="px-4 py-4 font-medium text-_black-50 whitespace-nowrap">
                                                                <Switch
                                                                    className="switch"
                                                                    checked={itemObj[item]}
                                                                    onChange={(checked) => {
                                                                        props.type === "orders" ? props.handleSwitch(checked, itemObj.order_id, index) : props.handleSwitch(checked, itemObj.restaurant_id, index);
                                                                    }}
                                                                />
                                                            </td>)}
                                                    </>
                                                )}
                                            </>
                                        ) : (
                                            <td className="px-6 py-4 font-medium text-_black-50 whitespace-nowrap">
                                                <Text size="poppinsRegularDescription">
                                                    {itemObj[item]}
                                                </Text>
                                            </td>
                                        )}
                                    </>
                                )}
                            </>
                        );

                    })}

                    {props.type === 'orders' ? (
                        <>
                            {itemObj.notified === true ? (
                                <td className="px-6 py-4 font-medium text-_black-50 whitespace-nowrap ">
                                    <FontAwesomeIcon icon={faCheck} className={`!text-_blue  !cursor-pointer`} />
                                </td>) : (
                                <td className="px-6 py-4 font-medium text-_black-50 whitespace-nowrap ">
                                    <FontAwesomeIcon icon={faBell} onClick={() => props.sendNotification(itemObj, true, index)} className={`!text-_blue  !cursor-pointer`} />
                                </td>
                            )}
                        </>
                    ) : null}

                    {props.type !== 'customer' ?
                        (<td className="px-6 py-4 font-medium text-_black-50 whitespace-nowrap ">
                            <Button size="small" className="border-_blue text-_blue" onClick={() => props.type === "orders" ? props.showModal(itemObj) : showComponent(itemObj)} > <Text size="poppinsRegularDescription">
                                {props.btnText}
                            </Text>
                            </Button>
                        </td>) : null}
                </tr>
            )
        })
    }

    const headerRenderer = () => {
        return (
            <tr>
                <th scope="col" className="px-6 py-3" >
                    <Text size="poppinsRegularDescription">
                        Sr no.
                    </Text>

                </th>
                {props.colName.map((head, index) => {
                    return (
                        <th scope="col" className="px-4 py-3" key={index}>
                            <Text size="poppinsRegularDescription">
                                {head}
                            </Text>
                        </th>
                    )
                })}
                {props.type === 'orders' ? (

                    <th scope="col" className="px-4 py-3" >
                        <Text size="poppinsRegularDescription">
                            Notify
                        </Text>
                    </th>


                ) : null}
                {props.type!=="customer"?(
                <th scope="col" className="px-4 py-3" >

                </th>):null}
            </tr>)

    }


    return (
        <div>
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
                <thead className=" sticky top-0 text-xs text-_black-50 uppercase bg-_white-170 z-[999] ">
                    {headerRenderer()}
                </thead>
                {props.dataList && props.dataList.length > 0 ? (
                    <tbody>
                        {rowRenderer()}
                    </tbody>
                ) : (
                    props.dataList === 0 ? (
                        <div className="w-full !items-center">
                            <tbody>
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            </tbody>
                        </div>
                    ) : (
                        <div className="w-full h-screen flex items-center justify-center">
                            {/* Adjust the style below to cover the whole screen */}
                            <Skeleton style={{ height: '100vh', width: '100vw' }} size={'large'} />
                        </div>

                    )
                )}


            </table>



        </div>
    )

}
export default TableRenderer