import React, { useEffect, useState } from "react";
import AdminSideBar from "../../component/admin/AdminSideBar";
import "../../styles/admin/mainPage.css"
import { Text } from "../../component/admin/Text";
import { Button, Form, Input, Modal} from "antd"
// import Switch from "../../component/Switch";
import TableRenderer from "../../component/tableRenderer";
import AdminDisplayOrder from "./AdminDisplayOrder";
import { dateWiseData, getOrderByStatus, notficationHandler, partialOrderData, updatePartObject } from "./BackendConnector"
import AdminStorage from "../../storage/AdminStorage";
import { useNavigate } from "react-router";
import DateSelect from "../../component/admin/DatePicker";


const { Search } = Input
const AdminHome = () => {

    const navigate = useNavigate();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [orderDetails, setOrderDetails] = useState({})

    const [notified, setNotified] = useState(false)

    const detailsToDisplay = ["order_time", "slot_time", "order_for", "item_count",  "order_total", "paid"]
    const colName = ["Date", "Order Slot", "Customer Name", "Customer Phone No.", "Total Items", "Order Total", "Paid"]
    const [date, setDate] = useState(null)


    const dateFormater = (funct) => {
        const formatedList = funct.map((order) => {
            // console.log(order.order_time)
            const dateFormat = String(new Date(order.order_time).getDate()) + '/' + String((new Date(order.order_time).getMonth()) + 1) + "/" + String(new Date(order.order_time).getFullYear())
            return { ...order, "order_time": dateFormat }

        })
        return formatedList
    }

    useEffect(() => {
        if (!AdminStorage.Instance.auth)
            navigate('/admin')
        //eslint-disable-next-line
    }, [])

    const [orderList, setOrderList] = useState([])

    const orderDataRetriever = async () => {
        let response = await partialOrderData("order");

        if (response) {
            let sorted = response.sort((a, b) => new Date(b.order_time) - new Date(a.order_time))
            setOrderList(dateFormater(sorted))
        }
    }

    useEffect(() => {
        orderDataRetriever()
        //eslint-disable-next-line
    }, [])


    const showModal = (order) => {
        setIsModalOpen(true);
        setOrderDetails(order);
    };

    const checkInput = async (value) => {
        if (document.getElementById("paid").checked || document.getElementById("completed").checked) {

            let response = ""

            if (document.getElementById("paid").checked && document.getElementById("completed").checked) {
                response = await getOrderByStatus('order/getOrderByStatus', "paid", "completed", date)
            }
            else {

                response = await getOrderByStatus('order/getOrderByStatus', document.getElementById("paid").checked ? "paid" : 'completed', "", date)
            }
            setOrderList(dateFormater(response))
        }
        else {
            orderDataRetriever()
        }
    }


    const handleDropdown = async (value, id, index) => {
        const response = await updatePartObject("order/updateOrder", id, value, 'paid')
        if (response) {
            const copy = [...orderList]
            copy[index].paid = value
            setOrderList(copy)
        }
    }
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };


    const [msg, setMsg] = useState({ pickUpLocation: "", deliveryTime: "" })

    const sendNotification = async (notificationData) => {
        const { order, value, index } = notificationData
        const { pickUpLocation, deliveryTime } = msg
        const response = await notficationHandler(order.order_for.email, order.order_for.name, order.invoice_id, pickUpLocation === "" ? order.pick_up_point : pickUpLocation, deliveryTime === "" ? order.slot_time : deliveryTime)
        if (response.status === 'SENDED') {
            const res = await updatePartObject("order/updateOrder", order.order_id, value, 'notified')
            if (res) {
                const orderCopy = [...orderList]
                orderCopy[index].notified = true
                setOrderList(orderCopy)
                setNotified(false)
            }
        }

    }

    const [initialValue, setInitialValues] = useState({ pickUpLocation: "", deliveryTime: "" })
    const [notificationData, setNotificationData] = useState({})

    const noticationHandler = (order, value, index) => {
        console.log(order.pick_up_point)
        setInitialValues((prev) => ({
            ...prev, 'pickUpLocation': order.pick_up_point
        }))
        setInitialValues((prev) => ({
            ...prev, 'deliveryTime': "30 mins"
        }))
        setNotificationData((prev) => ({
            ...prev, 'order': order
        }))
        setNotificationData((prev) => ({
            ...prev, 'value': value
        }))
        setNotificationData((prev) => ({
            ...prev, 'index': index
        }))
        setNotified(true)
    }



    const closeNotificationForm = () => {
        setNotified(false)
    }

    const dateRanger = async (date) => {
        if (date) {
            setDate(date.$d)
            const response = await dateWiseData("order", date.$d);
            setOrderList(dateFormater(response));
        }
        else {
            setDate(null)
            orderDataRetriever()
        }
    }

    return (
        <div className="mainPage" >
            <AdminSideBar />

            <div className="mainContent">
                <Text className="w-auto mb-5" size="poppinsMediumHeading">
                    Orders
                </Text>

                <div className="topBar my-3 ">
                    <div className="mx-1 mb-2 md:mx-0 md:my-0">
                        <DateSelect dateRanger={dateRanger} format="DD/MM/YYYY" className="!bg-_white-100" />
                    </div>
                    <ul className="justify-between items-center  md:mx-0  mx-1 md:w-checkbox w-1/2 md:w-1/4 bg-_white-170 rounded-lg sm:flex px-3 ">
                        <li className="">
                            <label className="flex items-center hover:cursor-pointer md:px-3">
                                <input id="paid" type="checkbox" className="w-4 h-4 text border border-gray-300 rounded focus:outline-none focus:border-_blue" style={{ backgroundColor: '#E0E0E0', borderColor: '#4180DF' }} onChange={(e) => checkInput('paid')} />
                                <span className="w-full py-2 ms-2 text-sm font-medium text-_black-100">Paid</span>
                            </label>
                        </li>
                        <li className="">
                            <label className="flex items-center hover:cursor-pointer md:px-3">
                                <input id="completed" type="checkbox" className="w-4 h-4 text border border-gray-300 rounded focus:outline-none focus:border-_blue" style={{ backgroundColor: '#E0E0E0', borderColor: '#4180DF' }} onChange={(e) => checkInput('completed')} />
                                <span className="w-full py-2 ms-2 text-sm font-medium text-_black-100">Completed</span>
                            </label>
                        </li>

                    </ul>

                    <Search placeholder="Try Searching Order No., Customer Name, Location" onSearch enterButton className="w-search" />

                </div>

                <div className="my-5 relative overflow-x-auto shadow-md sm:rounded-lg">
                    <TableRenderer sendNotification={noticationHandler} dataList={orderList} handleDropdown={handleDropdown} showModal={showModal} detailsToDisplay={detailsToDisplay} colName={colName} type="orders" btnText="View" openPopUp={''} />
                </div>


                {/* POPUP */}
                {isModalOpen ?

                    (

                        <Modal
                            width={600}
                            open={isModalOpen}
                            onOk={handleOk}
                            onCancel={handleCancel}
                            style={{ position: "sticky", top: 10, maxHeight: '90vh' }}
                            footer={null}>

                            <AdminDisplayOrder orderId={orderDetails.order_id} />

                        </Modal>

                    ) : (<>

                    </>)}
                {notified ? (
                    <>
                        <Modal
                            width={400}
                            open={notified}
                            onCancel={closeNotificationForm}

                            // style={{ position: "sticky", top: 10, maxHeight: '90vh' }}
                            footer={null}>
                            <div className="my-10">
                                <Form
                                    name="custom notification"
                                    style={{
                                        maxWidth: 600,
                                    }}
                                    initialValues={initialValue}
                                >
                                    <Form.Item
                                        name="pickUpLocation"
                                        label="Pick Up Location"
                                    >
                                        <Input onChange={(e) => setMsg((prev) => ({
                                            ...prev, 'pickUpLocation': e.target.value
                                        }))} />
                                    </Form.Item>
                                    <Form.Item
                                        name="deliveryTime"
                                        label="Delivery In"
                                    >
                                        <Input onChange={(e) => setMsg((prev) => ({
                                            ...prev, 'deliveryTime': e.target.value
                                        }))} />
                                    </Form.Item>
                                    <Form.Item >
                                        <Button htmlType="button" onClick={() => sendNotification(notificationData)}>
                                            Notify
                                        </Button>

                                    </Form.Item>
                                </Form>
                            </div>

                        </Modal>
                    </>
                ) : null}

            </div>
        </div>
    )
}
export default AdminHome
