
import { faClock } from '@fortawesome/free-regular-svg-icons'
import { faChevronRight, faPhone, faLocationDot } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const OrderHistoryList = ({ order }) => {
  return (
    <div className={"my-4 rounded-md shadow"}>
      <div
        onClick={() => { }}
        className={'flex cursor-pointer items-center py-4 pl-5 pr-5 ounded-md select-none bg-_white-100 '}
      >
        <div className='flex flex-col justify-start basis-6/12 md:flex-row md:items-center'>
          <div className='m-1 text-sm sm:basis-2/6 md:basis-1/6 lg:basis-4/12 break-all md:break-none'>Order #{order.order_id}</div>
          <div className='basis-4/6 '>
            <div >
              <div className=' md:grid md:grid-cols-2 gap-x-2 flex'>
                <div className='text-sm flex justify-end translate-y-1'>Delivery For: </div>
                <div className='text-md font-medium text-_blue'>{order.order_for.name}</div>
              </div>
              <div className=' md:grid md:grid-cols-2 gap-x-2 flex'>
                <div className='flex text-sm justify-end translate-y-1.5'><FontAwesomeIcon icon={faPhone} /></div>
                <div className='text-md font-medium '>{order.order_for.phone_number}</div>
              </div>
            </div>
          </div>
        </div>

        <div className='flex flex-col basis-6/12 md:flex-row md:items-center items-end'>
          <div className='basis-5/6 md:items-center flex flex-col'>
            <div>
              <div>
                <span className='text-base text-orange-400'><FontAwesomeIcon icon={faLocationDot} /></span>
                &nbsp;
                <span className='text-md font-medium'>{order.pick_up_point}, {order.city}</span>
              </div>
              <div>
                <span className=' text-base '><FontAwesomeIcon icon={faClock} /></span>
                &nbsp;
                <span className='text-md font-medium'>{order.slot_time}</span>
              </div>
            </div>
          </div>

          < div className='basis-1/6 text-lg flex md:justify-center gap-3 items-center font-semibold'>
          <span className=' text-green-500 whitespace-nowrap'>₹ {order.order_total} </span> 
          {order.paid === 'unPaid' ? <span>Processing</span> : <span>{order.paid.toUpperCase()}</span>}
          </div>
        </div>

        {/* <div className='basis-1/12 flex justify-end'><FontAwesomeIcon icon={faChevronRight} /></div> */}
      </div>
    </div>
  );
};

export default OrderHistoryList;
