import CartStorage from "./CartStorage";
import axios from "axios";

class UserStorage {
    auth = false;
    id = "";
    email = "";
    name = "";
    phone_number = "";
    subUser = [];

    listener = [];

    static get Instance() {
        if (!UserStorage._instance) {
            UserStorage._instance = new UserStorage();
        }
        return UserStorage._instance;
    }

    AddListener(component) {
        this.listener.push(component);
    }
    RemoveListener(component) {
        this.listener.filter((item) => item !== component);
    }

    LoadUserData() {
        const userCache = localStorage.getItem('user');
        if (userCache) {
            const userJson = JSON.parse(userCache);
            this.auth = userJson.auth;
            this.id = userJson.id;
            this.email = userJson.email;
            this.name = userJson.name;
            this.phone_number = userJson.phone_number;
            this.subUser = userJson.subUser;

            this.listener.forEach((callback) => {
                callback(prevState => prevState + 1);
            });
        }
    }

    async SetUserData() {
        if (this.auth) {
            const details = await axios.post(process.env.REACT_APP_URL + 'userDetails/updateSpecificDetails', { user_id: this.id, name: this.name, sub_user: this.subUser, phone_number: this.phone_number });
        }

        localStorage.setItem('user', JSON.stringify({
            "auth": this.auth,
            "id": this.id,
            "email": this.email,
            "name": this.name,
            "phone_number": this.phone_number,
            "subUser": this.subUser
        }));
        this.listener.forEach((callback) => {
            callback(prevState => prevState + 1);
        });
    }

    Login(json) {
        // Get from Database
        this.auth = true;
        this.id = json.id;
        this.email = json.email;
        this.name = json.name;
        this.phone_number = json.phone_number;
        this.subUser = json.subUser;
        this.SetUserData();


        if (CartStorage.Instance.itemCount === 0)
            CartStorage.Instance.LoadDatabaseData(this.id);
        else
            CartStorage.Instance.SetCacheData(this.id);
    }
    Logout() {
        this.auth = false;
        this.id = "";
        this.email = "";
        this.name = "";
        this.phone_number = "";
        this.subUser = [];
        this.SetUserData();
        CartStorage.Instance.DeleteCache();
    }

    ChangeDetails(name, ph) {
        this.name = name;
        this.phone_number = ph;
        this.SetUserData();
    }

    AddSubUser(newUser) {
        this.subUser.push(newUser);
        this.SetUserData();
    }

    RemoveSubUser(user) {
        this.subUser.filter((storedUser) => storedUser !== user);
        this.SetUserData();
    }

    SetSubUser(user, index) {
        this.subUser[index] = user;
        this.SetUserData();
    }

}

export default UserStorage;