import React, { useEffect, useState } from 'react'
import SnekoLogo from '../../assets/client/image/snekoLogoTrans.png'
import AdminStorage from '../../storage/AdminStorage';
import { useNavigate } from 'react-router';

function AdminLogin() {

  const navigate = useNavigate()
  const [user, setUser] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (AdminStorage.Instance.auth)
      navigate('/adminOrder')
  }, [])

  const loginAdmin = async () => {


    if (user === AdminStorage.Instance.user && password === AdminStorage.Instance.pass) {
      AdminStorage.Instance.auth = true;
      AdminStorage.Instance.SetCacheData();
      navigate('/adminOrder')
    }

    setErrorMessage('Incorrect Credentials');
  }

  return (
    <div >
      <div className="bg-white flex flex-colbg-white w-5/6 md:w-3/4 lg:w-2/3 xl:w-[400px] 2xl:w-[450px] mt-20 mx-auto px-15 py-5 rounded-lg ">

        <div className="container max-w-screen-sm mx-auto flex-1 flex flex-col items-center justify-center px-2">
          <div >
            <img className=" sm:mx-auto sm:w-32 sm:max-w-sm w- md:w-60 lg:w-48" src={SnekoLogo} alt="Your Company"></img>
          </div>
          <div className="bg-transparent px-10 py-1 rounded-xl  text-black w-full">
            <h1 className="text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">Sign Up</h1>
            <div className='text-red-500 text-center pt-3'>{errorMessage}</div>
            <div className="mt-3 relative ">
              <input type="text" id="full_name" onChange={(event) => { setUser(event.target.value) }} className="block border border-gray-300 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-white rounded-lg  appearance-none focus:outline focus:ring-0  peer" placeholder="" />
              <label htmlFor="full_name" className="absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 start-1 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">Username</label>
            </div>

            <div className="mt-3 relative ">
              <input type="password" id="phone_number" onChange={(event) => { setPassword(event.target.value) }} className="block border border-gray-300 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-white rounded-lg  appearance-none focus:outline focus:ring-0  peer" placeholder="" />
              <label htmlFor="phone_number" className="absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 start-1 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">Password</label>
            </div>


            <div className='flex justify-center mt-3'>
              <button
                type="submit"
                onClick={loginAdmin}
                className=" w-64 text-center font-semibold text-md py-1.5 rounded-md bg-_blue text-white hover:bg-green-dark focus:outline-none my-1"
              >Login</button>

            </div>
          </div>


        </div>
      </div>
    </div>
  )
}




export default AdminLogin