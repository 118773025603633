import { useState } from "react";
import { faChevronDown, faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UserStorage from "../../../storage/UserStorage";
import { useNavigate } from "react-router";

const DeliveryForDropdown = ({ callbackFx }) => {

  const [index, setIndex] = useState(-1);
  const [user, setUser] = useState({ name: UserStorage.Instance.name, phone_number: UserStorage.Instance.phone_number });
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate()

  const [newSubUserName, setNewSubUserName] = useState("");
  const [newSubUserPhone, setNewSubUserPhone] = useState("");
  const [newSubUserError, setNewSubUserError] = useState("");
  const [isNewSubUserMenu, setIsNewSubUserMenu] = useState(false);

  const ValueChange = (i) => {
    if (i === -1)
      setUser({ name: UserStorage.Instance.name, phone_number: UserStorage.Instance.phone_number, email: UserStorage.Instance.email })
    else
      setUser({ name: UserStorage.Instance.subUser[i].name, phone_number: UserStorage.Instance.subUser[i].phone_number, email: UserStorage.Instance.subUser[i].email })

    setIndex(i);
    setIsOpen(false);
    ResetNewSubUserMenu();
    callbackFx(i);
  }

  const ResetNewSubUserMenu = () => {
    setNewSubUserName("");
    setNewSubUserPhone("");
    setNewSubUserError("");
    setIsNewSubUserMenu(false);
  }

  const AddNewUser = () => {
    if (newSubUserName.length === 0) {
      setNewSubUserError("Name field is Empty")
      return;
    }
    if (Number.isNaN(newSubUserPhone) || newSubUserPhone.length !== 10) {
      setNewSubUserError("Phone number should be 10 digits")
      return;
    }
    UserStorage.Instance.AddSubUser({
      name: newSubUserName,
      phone_number: newSubUserPhone
    })
    ValueChange(UserStorage.Instance.subUser.length - 1)
  }

  return (
    <div onMouseLeave={() => {
      if (!isNewSubUserMenu) setIsOpen(false);
    }}
      className="relative group">
      <div onClick={() => { setIsOpen(!isOpen) }}
        className="flex cursor-pointer justify-between text-base border-2 border-_blue font-semibold text-_blue p-2 px-4 items-center gap-x-4 gap-y-2 rounded-md">
        <div>
          {UserStorage.Instance.auth ?
            <div className="flex flex-col">
              <span>{user.name}</span>
              <span>{user.phone_number}</span>
            </div> : <>--</>}
        </div>
        <div><FontAwesomeIcon icon={faChevronDown} /></div>
      </div>

      {isOpen &&
        <div className="absolute bg-gray-100 z-10 border border-gray-300 rounded-md right-0 ">
          {UserStorage.Instance.auth &&
            <div onClick={() => ValueChange(-1)}
              className={'block flex justify-between items-center cursor-pointer gap-10 px-4 w-full justify-center py-2 hover:bg-blue-200 ' + (index === -1 ? "text-_blue" : "text-gray-800 ")} >
              <div className="flex flex-col">
                <div className="max-w-full whitespace-nowrap">{UserStorage.Instance.name}</div>
                <div className="max-w-full whitespace-nowrap">{UserStorage.Instance.phone_number}</div>
              </div>
              {index === -1 && <FontAwesomeIcon icon={faCheck} />}
            </div>
          }
          {UserStorage.Instance.subUser.map((user, i) => (
            <div onClick={() => ValueChange(i)}
              className={'block flex justify-between items-center cursor-pointer gap-10 px-4 w-full justify-center py-2 hover:bg-blue-200 ' + (i === index ? "text-_blue" : "text-gray-800 ")}
              key={i}>
              <div className="flex flex-col">
                <div className="max-w-full whitespace-nowrap">{user.name}</div>
                <div className="max-w-full whitespace-nowrap">{user.phone_number}</div>
              </div>
              {i === index && <FontAwesomeIcon icon={faCheck} />}
            </div>
          ))}
          <div onClick={() => { }}
            className={'block flex justify-between items-center border-t-2 cursor-pointer gap-10 px-4 w-full justify-center py-2 '} >
            {isNewSubUserMenu ? (
              <div className="flex flex-col gap-2">
                <div className="relative w-64">
                  <input type="text" id="name" onChange={(event) => setNewSubUserName(event.target.value)}
                    className="block border-2 border-gray-300 outline-none px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-white rounded-lg focus:border-gray-400 peer" placeholder="" />
                  <label htmlFor="name" className={"absolute peer-focus:bg-gradient-to-t from-white to-_white-50 text-sm duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] px-2 peer-focus:px-2 peer-focus:text-_blue peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 start-2 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto " + (newSubUserName ? "text-_blue bg-gradient-to-t" : "text-gray-500")}>
                    Name</label>
                </div>
                <div className="relative w-64">
                  <input type="number" id="ph" onChange={(event) => setNewSubUserPhone(event.target.value)}
                    className="block border-2 border-gray-300 outline-none px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-white rounded-lg focus:border-gray-400 peer" placeholder="" />
                  <label htmlFor="ph" className={"absolute peer-focus:bg-gradient-to-t from-white to-_white-50 text-sm duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] px-2 peer-focus:px-2 peer-focus:text-_blue peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 start-2 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto " + (newSubUserPhone ? "text-_blue bg-gradient-to-t" : "text-gray-500")}>
                    Phone Number</label>
                </div>
                <label className={"text-xs text-red-500 font-light px-2 -mt-2"}>{newSubUserError}</label>
                <button onClick={() => AddNewUser()}
                  className='w-full block uppercase font-bold text-base bg-_blue text-white text-center p-2 rounded-md'>
                  Add
                </button>
                <button onClick={() => ResetNewSubUserMenu()}
                  className='w-full block uppercase font-bold text-base bg-red-500 text-white text-center p-2 rounded-md'>
                  Cancel
                </button>
              </div>
            ) : (
              <div className="max-w-full font-medium whitespace-nowrap">
                {UserStorage.Instance.auth ?
                  <div onClick={() => setIsNewSubUserMenu(true)}>Delivery for Someone Else</div>
                  : <div onClick={() => navigate('/login')}>Login to Continue</div>
                }
              </div>
            )
            }
          </div>
        </div>
      }
    </div>
  );
};

export default DeliveryForDropdown;