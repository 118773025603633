import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import {
    Button, Col,
    Form,
    Input,
    Row,
    Select,
    Space,
    Upload,
} from 'antd';
import AdminSideBar from '../../component/admin/AdminSideBar';
import { Text } from '../../component/admin/Text';
import "../../styles/admin/mainPage.css"

import ImageUploader from '../../component/admin/imageUploader';

import { dataSender, updateObject } from './BackendConnector';
import AdminStorage from '../../storage/AdminStorage';
import restaurantSvg from "../../assets/restaurant.svg"

const normFile = (e) => {
    if (Array.isArray(e)) {
        return e;
    }
    return e?.fileList;
};


const AdminAddRestaurant = () => {
    const location = useLocation()
    const navigate = useNavigate()

    const [addingData, setAddingData] = useState(null);
    const [isUploaded, setIsUploaded] = useState(false)
    const [restaurantDetail, setRestaurantDetail] = useState(location.state?.detail || null)
    const [outletLocations, setOutletLocations] = useState(location.state?.detail?.outlets || []);
    // const cuisinesOptions = []
    useEffect(() => {
        if (!AdminStorage.Instance.auth)
            navigate('/admin')
    }, [])


    const setDetails = (value, propName) => {
        setRestaurantDetail((prevDetail) => ({
            ...prevDetail, [propName]: value
        }))
    }
    const { uploadImage, removeImage } = ImageUploader({ setDetails, setIsUploaded, dir: "restaurant", propName: 'restaurant_img_path' });

    const [initialValues, setInitialValues] = useState(null)
    const otherInitialValues = {
        RestaurantName: restaurantDetail.restaurant_name,
        city: restaurantDetail.restaurant_city,
        cuisines: restaurantDetail.cuisines,
    }


    useEffect(() => {
        location.state?.detail?.outlets.forEach((outlet, index) => {
            setInitialValues((prev) => ({
                ...prev, ['outlet' + index]: outlet
            }))
        })

        Object.entries(otherInitialValues).forEach((item) => {
            setInitialValues((prev) => ({
                ...prev, [item[0]]: item[1]
            }))
        })
        //eslint-disable-next-line
    }, [location.state?.detail])

    useEffect(() => {
        setRestaurantDetail(location.state.detail)
        //eslint-disable-next-line
    }, [location.state])

    useEffect(() => {
        const addToDb = async () => {
            if (addingData === false) {
                if (location.state.type === 'Add') {

                    const response = await dataSender(restaurantDetail);
                    if (response) {
                        navigate("/adminRestaurant")
                    }
                } if (location.state.type === 'Edit') {
                    const response = await updateObject("restaurant/updateRestaurant", restaurantDetail);
                    if (response) {
                        navigate("/adminRestaurantDetail", { state: { id: restaurantDetail.restaurant_id } })
                    }

                }
            }
        }
        addToDb()
        //eslint-disable-next-line
    }, [addingData])



    const submit = async () => {
        setAddingData(true)
        setRestaurantDetail((prevDetail) => ({
            ...prevDetail, outlets: outletLocations
        }))
        setAddingData(false)
    }

    const addOutletLocation = () => {
        setOutletLocations([...outletLocations, ""]);
    };

    const removeOutletLocation = (index) => {
        const updatedLocations = [...outletLocations.slice(0, index), ...outletLocations.slice(index + 1)];
        setOutletLocations(updatedLocations);
    };

    const updateOutletLocation = (value, index) => {

        setOutletLocations((locations) => {

            const updatedOrders = [...locations];
            updatedOrders[index] = value;
            return updatedOrders;
        })


    }

    const handleRoute = () => {
        if (location.state.type === 'Add') {
            navigate("/adminRestaurant")
        }
        else {

            navigate("/adminRestaurantDetail", { state: { id: restaurantDetail.restaurant_id } })
        }
    }

    return (
        <div className='mainPage'>
            <AdminSideBar />
            {restaurantDetail &&
                <div className='mainContent'>
                    <Text className="w-auto" size="poppinsMediumHeading">
                        {location.state.type} Restaurants
                    </Text>

                    {/* FORM */}
                    {initialValues &&
                        <div className="my-5 relative overflow-x-auto ">
                            <Form
                                labelCol={{
                                    span: 5,
                                }}
                                wrapperCol={{
                                    span: 14,
                                }}
                                layout="horizontal"
                                style={{
                                    maxWidth: 600,
                                }}
                                onFinish={() => submit()}
                                initialValues={location.state.type === 'Edit' ? initialValues : {}}

                            >
                                <Form.Item name="userimage" label="Upload" valuePropName="fileList" getValueFromEvent={normFile}>
                                    {isUploaded ? (
                                        <Upload
                                            action="/upload.do"
                                            listType="picture-card"
                                            onRemove={() => removeImage({ url: restaurantDetail.restaurant_img_path!==''?restaurantDetail.restaurant_img_path:"" })}
                                        >
                                        </Upload>
                                    ) : (
                                        <Upload
                                            action="/upload.do"
                                            listType="picture-card"
                                            customRequest={uploadImage}
                                            defaultFileList={location.state.type === 'Edit' ? ([{
                                                uid: '-1',
                                                name: 'default.jpg',
                                                status: 'done',
                                                url: restaurantDetail.restaurant_img_path === '' ? restaurantSvg:restaurantDetail.restaurant_img_path,
                                            }]) : []}
                                        >
                                            <div>
                                                <PlusOutlined />
                                                <div style={{ marginTop: 8 }}>Upload</div>
                                            </div>
                                        </Upload>
                                    )}

                                </Form.Item>

                                <Form.Item name="RestaurantName" label="Restaurant Name" required={true}
                                    rules={[{ required: true, message: 'Please input the restaurant name!' }]}>
                                    <Input placeholder="Restarant Name" onChange={(e) => { setDetails(e.target.value, 'restaurant_name') }} />
                                </Form.Item>
                                <Form.Item name="city" label="City" rules={[
                                    {
                                        required: true,
                                        message: 'Please Input City',
                                    },
                                ]}>
                                    <Input onChange={(e) => { setDetails(e.target.value, "restaurant_city") }} />
                                </Form.Item>

                                <Form.Item name="cuisines" label="Cuisines">
                                    <Select
                                        mode="tags"
                                        style={{
                                            width: '100%',
                                        }}

                                        placeholder="Select Cusinies"
                                        onChange={(value) => { setDetails(value, 'cuisines') }}
                                    // options={restaurantDetail.cuisines}
                                    />
                                </Form.Item>

                                <Form.Item name="outletList" label="Outlet Location">
                                    <Space direction='vertical'>


                                        {outletLocations.map((_, index) => (
                                            (<Row key={index} gutter={[8, 8]} align="middle">
                                                <Col span={20}>
                                                    <Form.Item
                                                        label={index === 0 ? '' : ''}
                                                        name={"outlet" + index}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input Outlet Location',
                                                            },
                                                        ]}
                                                    >
                                                        <Input placeholder="Outlet Location" onChange={(e) => { updateOutletLocation(e.target.value, index) }} />
                                                    </Form.Item>
                                                </Col>
                                                {index === outletLocations.length - 1 && index !== 0 && (
                                                    <Col span={4}>
                                                        <Button
                                                            type="link"
                                                            icon={<MinusOutlined />}
                                                            onClick={() => removeOutletLocation(index)}
                                                        >
                                                            Remove
                                                        </Button>
                                                    </Col>
                                                )}
                                            </Row>)
                                        ))}

                                        <Row>
                                            <Col span={24}>
                                                <Form.Item>
                                                    <Button type="dashed" onClick={addOutletLocation} icon={<PlusOutlined />}>
                                                        Add Outlet Location
                                                    </Button>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Space>
                                </Form.Item>

                                <Form.Item className=' w-full'>

                                    <Button type="primary" onClick={handleRoute} className='w-32 mx-2 bg-_blue hover:bg-blue-700 hover:text-_white-100 ' size='medium'>
                                        Cancel
                                    </Button>

                                    <Button htmlType='submit' type="primary" className='w-32 mx-2 bg-_blue hover:bg-blue-700 hover:text-_white-100 ' size='medium'>
                                        {location.state.type}
                                    </Button>

                                </Form.Item>


                            </Form>

                        </div>}
                </div>

            }



        </div>
    )
}
export default AdminAddRestaurant