import { useState } from 'react';

const QuantityButton = ({ callbackFx, initialValue, minValue }) => {

  const [value, setValue] = useState(initialValue);

  const decrement = () => {
    const newValue = Math.max(minValue, value - 1);
    setValue(newValue);
    callbackFx(newValue);
  };
  const increment = () => {
    const newValue = value + 1;
    setValue(newValue);
    callbackFx(newValue);
  };

  return (
    <div className="flex justify-between text-_blue font-bold p-2">
      <button onClick={decrement} disabled={value === minValue} className='px-2 sm:px-4 disabled:text-gray-300'> - </button>
      <div className='px-2 sm:px-4'> {value} </div>
      <button onClick={increment} className='px-2 sm:px-4'> + </button>
    </div>
  );
};

QuantityButton.defaultProps = {
  callbackFx: () => { },
  initialValue: 0,
  minValue: 0,
};

export default QuantityButton;
