import { useContext } from "react";
import CartContext from "../../../context/CartContext";
import CartRestaurant from "./CartRestaurant";

const Cart = () => {
  const { cart } = useContext(CartContext);
  
  return (
    <ul className='basis-7/12'>
      { cart.cartItems &&
        cart.cartItems.map((restaurant, i) => (
          <CartRestaurant restaurant={restaurant} key={i}/>
        ))}
    </ul>
      );
};

export default Cart;
