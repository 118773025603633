import { useState, useContext, useEffect } from 'react';

import QuantityButton from "../other/QuantityButton";
import RestaurantContext from '../../../context/RestaurantContext';
import CartStorage from '../../../storage/CartStorage'
import FoodType from '../other/FoodType';
import MultiSelectDropdown from '../other/MultiselectDropdown';

const RestaurantMenuItem = ({ item }) => {
  const [qty, setQty] = useState(0);
  const restaurant = useContext(RestaurantContext);

  const [addOnSelected, setAddOnSelected] = useState([]);

  const initialImgSrc = item.item_img_path;
  const [imgSrc, setImgSrc] = useState(initialImgSrc);

  const handleImageError = () => {
    setImgSrc(require("../../../assets/item.png"));
  };

  const handleAddOnChange = (value) => {
    setAddOnSelected(value)
    const add_ons = Array.from(value, (x) => {
      return {type: `${x.value}`, price: x.price}
    });
    CartStorage.Instance.AddOnChange(restaurant.restaurant_id, item.item_id, add_ons);
  };

  useEffect(() => {
    setQty(CartStorage.Instance.GetQuantity(restaurant.restaurant_id, item.item_id));
    const fetch = CartStorage.Instance.GetAddOn(restaurant.restaurant_id, item.item_id);
    const addon = Array.from(fetch, (x) => {
      return {value: `${x.type}`, price: x.price,  label: `${x.type} (₹ ${x.price})`}
    });
    setAddOnSelected(addon);
  }, []);

  const SetQuantity = (value) => {
    if (value == 0){
      setAddOnSelected([])
      CartStorage.Instance.RemoveFromCart(restaurant.restaurant_id, item.item_id);
    }
    else if (qty == 0){
      CartStorage.Instance.AddToCart({
        restaurant_id: restaurant.restaurant_id,
        restaurant_name: restaurant.restaurant_name,
        restaurant_img_path: restaurant.restaurant_img_path,
        item: {
          item_id: item.item_id,
          item_name: item.item_name,
          item_price: Number(item.item_price),
          item_img_path: item.item_img_path,
          qty: value,
          item_type: item.item_type,
          add_ons:addOnSelected
        }
      });
    }
    else{
      CartStorage.Instance.ChangeQuantity(restaurant.restaurant_id, item.item_id, value);
    }

    setQty(value);
  }

  return (
    <>
      <div className='basis-8/12 space-y-1'>
        <h2 className='flex items-center text-base font-semibold'>
          <FoodType type={item.item_type} />
          {item.item_name}
        </h2>
        <p className='text-xs font-semibold'>₹ {item.item_price}</p>
        <p className='text-xs hidden md:block'>
          {item.description}
        </p>
        {(qty !== 0 && item.add_ons.length !== 0) && (
          <div className='w-[75%]'>
          <MultiSelectDropdown list={item.add_ons} selected={addOnSelected} onChange={handleAddOnChange}/>
          </div>
        )}
      </div>

      <div className='w-full basis-4/12 relative'>
        <img
          className='w-full h-32 aspect-video object-cover rounded-md'
          src={imgSrc}
          alt='item'
          onError={handleImageError}
        />
        {qty == 0 ? (
          <button onClick={() => SetQuantity(1)}
            className='bg-_blue text-white hover:bg-white hover:text-_blue font-bold p-2 px-6 rounded-md absolute shadow-md left-[50%] -bottom-5 -translate-x-[50%]'>
            ADD
          </button>
        ) : (
          <div className='rounded-md absolute shadow-md bg-white left-[50%] -bottom-5 -translate-x-[50%]'>
            <QuantityButton callbackFx={(value) => SetQuantity(value)} initialValue={qty} />
          </div>
        )
        }
      </div>
    </>
  );
};
export default RestaurantMenuItem;
