class AdminStorage {
  user = "adminsneko";
  pass = "sneko@accessgranted";
  auth = false;

  static get Instance() {
    if (!AdminStorage._instance) {
      AdminStorage._instance = new AdminStorage();
    }
    return AdminStorage._instance;
  }

  LoadCacheData() {
    const cartCache = localStorage.getItem('nekos');
    if (cartCache) {
      const cartJson = JSON.parse(cartCache);
      this.auth = cartJson.auth;
    }
  }
  SetCacheData() {
    localStorage.setItem('nekos', JSON.stringify({
      "auth": this.auth
    }));
  }

  Logout() {
    localStorage.clear()
    this.auth = false;
  }
}

export default AdminStorage