import { useParams } from "react-router-dom";

import Navbar from "../../component/client/Navbar";
import useOrderHistory from "../../hooks/useOrderHistory";
import OrderHistory from "../../component/client/orderHistory/OrderHistory";

const OrderHistoryPage = () => {

  


  const { id } = useParams();
  const { orderDetail, isLoading, error } = useOrderHistory({ id: id });
  const OpenWhatsapp = () => {
    const externalUrl = 'https://wa.me/+919065652233'; // Replace with your external URL
    window.open(externalUrl, '_blank');
}

  

  return (
    <div className='relative'>
      <Navbar />
      <div className='container-max py-4 '>
        <h1 className='text-2xl my-4 font-semibold'>Order History</h1>
        <OrderHistory isLoading={isLoading} orderDetails={orderDetail} error={error} />
      </div>
      <div>
          <h1 className='text-xl text-center p-4 border-t-2'>
                         For any queries reach out to us on Whatsapp.
          </h1>
      </div>
      <div className="flex justify-center">
      
      <button onClick={() => OpenWhatsapp()}
                    className='bg-_blue text-white font-semibold p-2 px-6 rounded-md shadow-md '>
                    Whatsapp
      </button>
      </div>
    </div>

  );
};

export default OrderHistoryPage;
