const OrderDetailsSummary = ({orderDetail}) => {

  return (
    <div className='basis-5/12 h-fit p-8 rounded-md border shadow-md my-8 md:m-0'>
      <h2 className='text-xl font-semibold border-b pb-4'>Order Summary</h2>

      <div className='py-4 text-lg space-y-4 border-b'>
        <div className='flex justify-between font-medium'>
          <div className='font-light  h-full'>Delivery for</div>
          <div>
            <div>{orderDetail.order_for.name}</div>
            <div>{orderDetail.order_for.phone_number}</div>
          </div>
        </div>
        <div className='flex justify-between font-medium'>
          <div className='font-light  h-full'>Slot Time</div>
            <div>{orderDetail.slot_time}</div>
        </div>
        <div className='flex justify-between font-medium'>
          <div className='font-light  h-full'>Pick-Up-Point</div>
            <div>{orderDetail.pick_up_point}</div>
        </div>
      </div>

      <div className='py-4 text-lg space-y-4 border-b'>
        <div className='flex justify-between items-center font-medium'>
          <p className='font-light'>Price ({orderDetail.item_count} items)</p>
          <p>₹ {orderDetail.order_total}</p>
        </div>
        <div className='flex justify-between items-center font-medium'>
          <p className='font-light'>Delivery charges</p>
          { orderDetail.delivery_charge == 0 ?
             <p>FREE</p>
            :
             <p>₹ {orderDetail.delivery_charge}</p>
          }
        </div>
      </div>

      <div className='py-4 text-lg space-y-4 border-b'>
        <div className='flex justify-between items-center font-medium'>
          <p className='font-light'>Total Amount</p>
          <p>₹ {orderDetail.order_total + orderDetail.delivery_charge}</p>
        </div>
{/*         <div className='flex justify-between items-center font-medium'>
          <p className='font-light'>Invoice no</p>
          <p>{orderDetail.invoice_id}</p>
        </div> */}
      </div>
    </div>
  );
};

export default OrderDetailsSummary;
