import React from "react";
import Navbar from "../../component/client/Navbar";

const OrderPlaced = () => {

    const OpenWhatsapp = () => {
        const externalUrl = 'https://wa.me/+919065652233'; // Replace with your external URL
        window.open(externalUrl, '_blank');
    }

    return (
        <div className="container-max">
            <Navbar />
            <div className='min-h-screen flex flex-col justify-center items-center'>
                <h1 className='text-4xl font-bold uppercase my-4 flex gap-2 items-center'>
                    Order has been Placed
                </h1>
                <h1 className='text-xl text-center p-4'>
                    You will recieve an email when your food is about to reach its destination. For any queries reach out to us on Whatsapp.
                </h1>
                <button onClick={() => OpenWhatsapp()}
                    className='bg-_blue text-white font-semibold p-2 px-6 rounded-md shadow-md '>
                    Whatsapp
                </button>
            </div>
        </div>
    );
};
export default OrderPlaced;
