import React, { useEffect, useState } from "react";
import AdminSideBar from "../../component/admin/AdminSideBar";
import "../../styles/admin/mainPage.css"
import { Text } from "../../component/admin/Text";
import { Input } from "antd";
import TableRenderer from "../../component/tableRenderer";
import { useNavigate } from "react-router";
import AdminStorage from "../../storage/AdminStorage";
import { dataRetriever } from "./BackendConnector";
const { Search } = Input

const AdminCustomer = (props) => {

    const navigate = useNavigate();

    const [customerList, setCustomerList] = useState([])
    useEffect(() => {
        if (!AdminStorage.Instance.auth)
            navigate('/admin')
    }, [])

    useEffect(() => {
        const customerDetails = async () => {
            let response = await dataRetriever("order/customer");
            // if (response) {
            setCustomerList(response)
            // }
        }
        customerDetails();
    }, [])

    const tempData = [
        {
            ProfilePhoto: "",
            Name: "Hafeez",
            PhoneNo: "+9173983943",
            EmailId: "hafeez0101@gmail.com",
            Age: "91",
            AvgCartValue: "2100",
            Location: "Hyderabad"
        },
        {
            ProfilePhoto: "",
            Name: "Hafeez",
            PhoneNo: "+9173983943",
            EmailId: "hafeez0101@gmail.com",
            Age: "91",
            AvgCartValue: "2100",
            Location: "Hyderabad"
        },
        {
            ProfilePhoto: "",
            Name: "Hafeez",
            PhoneNo: "+9173983943",
            EmailId: "hafeez0101@gmail.com",
            Age: "91",
            AvgCartValue: "2100",
            Location: "Hyderabad"
        },
        {
            ProfilePhoto: "",
            Name: "Hafeez",
            PhoneNo: "+9173983943",
            EmailId: "hafeez0101@gmail.com",
            Age: "91",
            AvgCartValue: "2100",
            Location: "Hyderabad"
        },
        {
            ProfilePhoto: "",
            Name: "Hafeez",
            PhoneNo: "+9173983943",
            EmailId: "hafeez0101@gmail.com",
            Age: "91",
            AvgCartValue: "2100",
            Location: "Hyderabad"
        },
    ]


    const detailsToDisplay = ["name", "email", "phone_number", "total", "avg", 'frequency']
    const colName = ["Name", "Email", "Phone Number", "Total Order Value", "Avg Order Value", "Frequency"]
    return (
        <div className="mainPage" >
            <AdminSideBar />
            <div className="mainContent">
                <Text className="w-auto mb-5" size="poppinsMediumHeading">
                    Customers
                </Text>
                <div className="topBar !justify-end my-3">
                    {/* <Search placeholder=" Name, Address, PhoneNo" /> */}
                    <Search placeholder="Try Searching Restaurant Name, Location, Operational" allowclear onSearch enterButton className="w-search"
                    />
                </div>
                <div className="my-5 relative overflow-x-auto shadow-md sm:rounded-lg">
                    <TableRenderer detailsToDisplay={detailsToDisplay} colName={colName} dataList={customerList} type="customer" btnText="view" />
                </div>
            </div>
        </div>
    )
}
export default AdminCustomer