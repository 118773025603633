import { Link, useParams } from "react-router-dom";
import DisplayError from "../DisplayError";
import ShimmerOrderHistory from "../shimmer/ShimmerOrderHistory";
import OrderHistoryList from "./OrderHistoryList";

const OrderHistory = ({orderDetails, isLoading, error}) => {

  const { id } = useParams();

  return (
    <div>
      { error ? (
          <DisplayError description={error}/>
        ) : (
        <div className=''>
          { isLoading ? (
              Array.from({ length: 2 }).map((_, i) => <ShimmerOrderHistory key={i} />)
            ) : (
              <ul>
                { orderDetails.map((order, i) => (
                  <Link
                    to={`/orderHistory/${id}/${order.order_id}`}
                    className='hover:scale-95 transition ease-in-out duration-300 relative z-10'
                    key={i}
                  >
                    <OrderHistoryList order={order} key={i} /> 
                  </Link>
                  ))
                }
              </ul>
            )
          }
        </div>
        )
      }
    </div>
  );
};

export default OrderHistory;
