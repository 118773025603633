import React from "react";

const DisplayError = ({ title, description }) => {
    return (
        <div className='flex flex-col justify-center items-center'>
            <h1 className='text-4xl font-bold uppercase my-4 flex gap-2 items-center'>
                {title}
            </h1>
            <h1 className='md:text-xl text-l'>
                {description}
            </h1>
        </div>
    );
};
export default DisplayError;
