import React from "react";
import Navbar from "../component/client/Navbar";

const NotFound = () => {
    return (
        <div>
            <Navbar />
            <div className='min-h-screen flex flex-col justify-center items-center'>
                <h1 className='text-4xl font-bold uppercase my-4 flex gap-2 items-center'>
                    Oops!!
                </h1>
                <h1 className='text-xl'>
                    404: Not Found
                </h1>
            </div>
        </div>
    );
};
export default NotFound;
