
import React from "react";
import Routes from "./helper/Routes";
import CartStorage from "./storage/CartStorage";
import UserStorage from "./storage/UserStorage";
import AdminStorage from "./storage/AdminStorage";

function App() {
  UserStorage.Instance.LoadUserData();
  CartStorage.Instance.LoadCacheData();
  AdminStorage.Instance.LoadCacheData();

  return (
    <div>
      <Routes />
    </div>
  );
}

export default App;
