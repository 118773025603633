import { Link, useParams } from "react-router-dom";

import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Navbar from "../../component/client/Navbar";
import useOrderHistory from "../../hooks/useOrderHistory";
import OrderDetails from "../../component/client/orderHistory/details/OrderDetails";
import OrderDetailsSummary from "../../component/client/orderHistory/details/OrderDetailsSummary";
import DisplayError from "../../component/client/DisplayError";
import ShimmerOrderDetails from "../../component/client/shimmer/ShimmerOrderDetails";

const OrderHistoryDetailsPage = () => {
  const { id, orderID } = useParams();
  const { orderDetail, isLoading, error } = useOrderHistory({ id: id, orderID: orderID });

  return (
    <div className='relative'>
      <Navbar />
      <div className='container-max py-4 pb-16'>
        <h1 className='text-2xl my-4 font-semibold'>
          <Link
            to={`/orderHistory/${id}`}
            className='hover:scale-95 transition ease-in-out duration-300 relative z-10'
          >
            <FontAwesomeIcon icon={faArrowLeft} />
          </Link>
          <span> &nbsp; Order #{orderID} </span>
        </h1>

        {error ? (
          <DisplayError description={error} />
        ) : isLoading ? (
          <ShimmerOrderDetails />
        ) : (
          <div className='min-h-[60vh] pb-8 md:flex md:flex-row-reverse gap-8'>
            <OrderDetailsSummary orderDetail={orderDetail} />
            <OrderDetails orderDetail={orderDetail} />
          </div>
        )
        }
      </div>
    </div>
  );
};

export default OrderHistoryDetailsPage;
