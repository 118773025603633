import React, { useEffect, useState } from 'react';

import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Radio, Row, Space, Upload, } from 'antd';

import ImageUploader from '../../component/admin/imageUploader';
import DropDown from '../../component/admin/DropDown';
import { v4 as uuid } from 'uuid';
import itemSvg from "../../assets/item.svg"

const normFile = (e) => {
    if (Array.isArray(e)) {
        return e;
    }
    return e?.fileList;
};

const { TextArea } = Input



const AdminAddItem = (props) => {



    const [menuItem, setMenuItem] = useState(props?.menuItem || {})
    const [changed, setIsChanged] = useState(false)
    const [isUploaded, setIsUploaded] = useState(false)
    const [form] = Form.useForm();
    const [addons, setAddons] = useState(props.menuItem.items.add_ons.length === 1 && props.menuItem.items.add_ons[0].type.length === 0 ? [] : props.menuItem.items.add_ons);
    const [initialValues, setInitialValues] = useState(null)

    useEffect(() => {
        if (changed) {
            setDetails(addons, "add_ons")
            setIsChanged(false)
        }
        //eslint-disable-next-line
    }, [changed])

    useEffect(() => {

        const setImageUploaded = () => {
            if (props.menuItem.items.item_img_path.length !== 0 && props.type === 'edit') {
                setIsUploaded(true)
            }
        }
        const setItemId = () => {
            if (props.type === 'add') {
                setMenuItem((prev) => ({
                    ...prev, items: {
                        ...prev.items, "item_id": uuid()
                    }
                }))
            }
        }
        setItemId()
        setImageUploaded()
    }, [])


    const typeOptions = [
        { label: 'Veg', value: 'veg' },
        { label: 'Non Veg', value: 'nonVeg' },
        { label: 'Jain', value: 'jain' },
    ];


    const updateAddOn = (value, index, propName) => {

        setIsChanged(false)
        setAddons((addon) => {
            const updated = [...addon];
            updated[index] = { ...updated[index], [propName]: value }
            return updated;
        })
        setIsChanged(true)
    }

    const otherInitialValues = {
        itemName: menuItem.items.item_name,
        price: menuItem.items.item_price,
        description: menuItem.items.description,
        foodType: menuItem.items.item_type,
    }

    useEffect(() => {
        props.menuItem.items.add_ons.map((addOn, index) => {

            setInitialValues((prev) => ({
                ...prev, ['addOn' + index]: addOn.type
            }))

            setInitialValues((prev) => ({
                ...prev, ['price' + index]: addOn.price
            }))

        })
        Object.entries(otherInitialValues).map((value) => {
            setInitialValues((prev) => ({
                ...prev, [value[0]]: value[1]
            }))
        })
        //eslint-disable-next-line
    }, [])

    const addAddOn = () => {
        setAddons([...addons, { type: "", price: 0 }]);
    };

    const removeAddOn = (index) => {
        setIsChanged(false)
        const updated = addons.filter((_, i) => i !== index);
        setAddons(updated);
        setIsChanged(true)
    };


    const setDetails = (value, propName) => {

        if (propName === "category") {
            setMenuItem((prevDetail) => ({
                ...prevDetail, [propName]: value
            }))
        }
        else {
            setMenuItem((prevDetail) => ({
                ...prevDetail, items: {
                    ...prevDetail.items, [propName]: value
                }
            }))

        }
    }

    const setMenu = (value) => {
        // checking if category exist, updates it
        if (props.type === 'edit') {
            const index = props.catList[value.category]
            let copy = { ...props.restDetail }
            copy.menu[index].items[props.itemIndex] = value.items
            props.setRestDetail(copy)
            props.setCatIndex(index)
        }
        else {

            if (Object.keys(props.catList).includes(value.category)) {
                const index = props.catList[value.category]
                const copy = { ...props.restDetail }
                copy.menu[index].items.push(value.items)
                props.setRestDetail(copy)
                props.setCatIndex(index)
            }
            else {
                // adds new category
                props.setRestDetail((prevDetail) => ({

                    ...prevDetail,
                    menu: [
                        ...prevDetail.menu,
                        {
                            category: value.category,
                            items: [value.items]
                        }
                    ]
                }));
                props.setCatIndex(null)
            }

        }
        setMenuItem({
            category: "",
            items:
            {
                item_id: "",
                item_img_path: "",
                item_type: "",
                item_name: "",
                item_price: 0,
                description: "",
                add_ons: [{
                    type: "",
                    price: 0
                }],
                available: true

            }
        })
        props.setIsUpdate(true)
    };



    const { uploadImage, removeImage } = ImageUploader({ setDetails, setIsUploaded, dir: "item", propName: 'item_img_path' });


    return (
        <div>

            {menuItem && initialValues &&
                (<>

                    <div className="my-5 relative overflow-x-auto ">

                        <Form
                            labelCol={{
                                span: 6,
                            }}
                            form={form}
                            wrapperCol={{
                                span: 14,
                            }}
                            layout="horizontal"
                            style={{
                                maxWidth: 700,
                            }}
                            onFinish={() => { setMenu(menuItem); props.setIsModalOpen(false) }}
                            initialValues={props.type === 'edit' ? initialValues : {}}
                        >
                            <Form.Item name="itemImage" label="Upload" valuePropName="fileList" getValueFromEvent={normFile}>
                                {isUploaded ? (
                                    <Upload
                                        action="/upload.do"
                                        listType="picture-card"
                                        onRemove={() => removeImage({ url: menuItem.items.item_img_path })}
                                    >
                                        {/* Your uploaded image preview */}
                                    </Upload>
                                ) : (
                                    <Upload
                                        action="/upload.do"
                                        listType="picture-card"
                                        customRequest={uploadImage}
                                        defaultFileList={props.type === 'edit' ? ([{
                                            uid: '-1',
                                            name: 'item.jpg',
                                            status: 'done',
                                            url: menuItem.items.item_img_path === '' ? itemSvg : menuItem.items.item_img_path,
                                        }]) : ([])}
                                    >
                                        <div>
                                            <PlusOutlined />
                                            <div style={{ marginTop: 8 }}>Upload</div>
                                        </div>
                                    </Upload>
                                )}

                            </Form.Item>

                            <Form.Item name="Category" label={"Category"}

                                rules={[{ required: props.type === 'edit' ? false : true, message: 'Please input the Category!' }]}>
                                <DropDown form={form} disabled={props.type === 'edit' ? true : false} defaultCategory={menuItem.category} category={Object.keys(props.catList)} setDetails={setDetails} required={props.type === 'edit' ? false : true} />
                            </Form.Item>

                            <Form.Item name="itemName" label="Item Name" required={true}
                                rules={[{ required: true, message: 'Please enter the Item name!' }]}>
                                <Input onChange={(e) => { setDetails(e.target.value, 'item_name') }} />
                            </Form.Item>
                            <Form.Item name="price" label="Price" required={true}
                                rules={[{ required: true, message: 'Please input price!' }]}>
                                <Input prefix="₹" suffix="INR" type='Number' value={menuItem.items.item_price} onChange={(e) => { setDetails(Number(e.target.value), "item_price") }} />
                            </Form.Item>
                            <Form.Item name='description' label="Description">
                                <TextArea showCount value={menuItem.items.item_description} maxLength={500} placeholder="Enter Description" onChange={(e) => { setDetails(e.target.value, "description") }} />
                            </Form.Item>

                            <Form.Item name='foodType' label="Food Type">

                                <Radio.Group
                                    options={typeOptions}
                                    onChange={(e) => setDetails(e.target.value, "item_type")}
                                    optionType="button"
                                    buttonStyle="solid"
                                />
                            </Form.Item>

                            <Form.Item name='addons' label="Add Ons">
                                <Space direction='vertical'>
                                    {addons.length !== 0 ? (addons.map((addon, index) => (
                                        <Row key={index} gutter={[8, 8]} >
                                            <Col span={10}>
                                                <Form.Item

                                                    required={true}
                                                    name={"addOn" + index}
                                                    rules={[{ required: true, message: 'Please enter Add Ons!' }]}
                                                >
                                                    <Input placeholder="Add On" value={addon[index]} onChange={(e) => updateAddOn(e.target.value, index, "type")} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={10}>
                                                <Form.Item
                                                    name={"price" + index}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please enter Price ',
                                                        },
                                                    ]}
                                                >
                                                    <Input prefix="₹" suffix="INR" type="Number" placeholder="Price" onChange={(e) => updateAddOn(Number(e.target.value), index, "price")} />
                                                </Form.Item>
                                            </Col>


                                            {index === addons.length - 1 ?
                                                (<Col span={4}>
                                                    <Button
                                                        type="link"
                                                        icon={<MinusOutlined />}
                                                        onClick={() => removeAddOn(index)}
                                                    >
                                                        Remove
                                                    </Button>
                                                </Col>) : null}

                                        </Row>
                                    ))) : null}
                                    <Row>
                                        <Col span={14}>
                                            <Form.Item>
                                                <Button type="dashed" onClick={addAddOn} icon={<PlusOutlined />}>
                                                    Add Add On
                                                </Button>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Space>
                            </Form.Item>
                            <Form.Item className=' w-full flex justify-end'>
                                <Button htmlType='submit' type="primary" className='w-32 mx-2 bg-transparent border-_black-50 text-_black-100  hover:!border-transparent hover:!bg-_blue hover:!text-_white-100 ' size='medium'>
                                    {(props.type).toUpperCase()}
                                </Button>
                            </Form.Item>


                        </Form>

                    </div>
                </>)
            }

        </div>
    )
}
export default AdminAddItem
