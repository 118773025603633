import { useContext } from 'react';
import RestaurantMenuCategory from './RestaurantMenuCategory';
import RestaurantContext from '../../../context/RestaurantContext';

const RestaurantMenu = () => {
  const { menu } = useContext(RestaurantContext);

  return (
    <div className='my-4'>
      {menu?.map((m, i) => (
        <RestaurantMenuCategory category={m} key={i} />
      ))}
    </div>
  );
};

export default RestaurantMenu;
