import { useContext, useEffect, useState } from "react";
import CartContext from "../../../context/CartContext";
import QuantityButton from "../other/QuantityButton";
import FoodType from "../other/FoodType";

const CartItem = ({ restaurant_id, item }) => {
  const { cart, setForceUpdate } = useContext(CartContext);

  const [_, setQty] = useState(0);


  const initialImgSrc = item.item_img_path;
  const [imgSrc, setImgSrc] = useState(initialImgSrc);

  const handleImageError = () => {
    setImgSrc(require("../../../assets/item.png"));
  };

  useEffect(() => {
    setQty(cart.GetQuantity(restaurant_id, item.item_id));
  }, []);

  const SetQuantity = (value) => {
    cart.ChangeQuantity(restaurant_id, item.item_id, value);
    setQty(value);
    setForceUpdate(prevState => prevState + 1);
  }

  const RemoveItem = () => {
    cart.RemoveFromCart(restaurant_id, item.item_id);
    setForceUpdate(prevState => prevState + 1);
  }


  return (
    <>
      <div className='basis-3/12'>
        <img
          className='w-full h-full md:h-auto object-cover block rounded-md aspect-square'
          src={imgSrc}
          alt='item'
          onError={handleImageError}
        />
      </div>
      <div className='basis-9/12'>
        <p className='flex items-center text-lg font-semibold'>
          <FoodType />
          {item.item_name}
        </p>
        <p className='my-2 space-x-1'>
          <span className='font-semibold'>
            ₹ {item.item_price * item.qty}
          </span>
          <span className='text-gray-800 font-normal'>
            ( {item.item_price} x {item.qty} )
          </span>
        </p>
        <div className='flex justify-between items-center mt-2 gap-2'>
          <div className='rounded-md border-2 border-_blue text-sm'>
            <QuantityButton callbackFx={(value) => SetQuantity(value)} initialValue={item.qty} minValue={1} />
          </div>
          <button
            onClick={() => RemoveItem()}
            className='border-2 border-_blue text-sm font-semibold text-_blue p-2 px-4 rounded-md hover:text-white hover:bg-_blue'
          >
            Remove
          </button>
        </div>
      </div>
    </>
  );
};

export default CartItem;
