// import defaultImg from "../../../assets/restaurant.png"

import { useState } from "react";

const RestaurantCard = ({ restaurant }) => {
  const { restaurant_name, restaurant_img_path, cuisines } = restaurant;

  const initialImgSrc = restaurant_img_path;
  const [imgSrc, setImgSrc] = useState(initialImgSrc);

  const handleImageError = () => {
    setImgSrc(require("../../../assets/restaurant.png"));
  };


  return (
    <div className="drop-shadow-sm ">
      <div className='overlay-container'>
        <img
          src={imgSrc}
          alt='restaurant'
          onError={handleImageError}
          className='relative w-full min-h-[180px] overflow-hidden aspect-video object-cover block rounded-md'
        />
        <div className='overlay w-full rounded-md pt-2 '>
          <p className='md:text-l pl-2 text-sm font-bold flex gap-2 flex-wrap'>
            {restaurant_name}
          </p>
        </div>
      </div>

      <p className='truncate pl-2 md:text-l text-sm ext-zinc-600'>
        {cuisines?.map((c, i) => (
          <span key={i}>
            {c}
            {i == cuisines.length - 1 ? '' : ', '}
          </span>
        ))}
      </p>
    </div>
  );
};

export default RestaurantCard;
