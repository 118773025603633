import React, { useState } from 'react';
import Select from 'react-select';

const MultiSelectDropdown = ({list, selected, onChange}) => {
  const options = Array.from(list, (x) => {
    return {value: `${x.type}`, price: x.price,  label: `${x.type} (₹ ${x.price})`}
  });

  const handleChange = (selected) => {
    onChange(selected);
  };

  const customStyles = {
    control: (provided,state) => ({
      ...provided,
      borderRadius: '0.375rem', // Rounded border
      minHeight: '2.5rem', // Reduced height
      '&:hover': {
        borderColor: state.isFocused ? '#4299e1' : '#4299e1', // Maintain color when focused, blue otherwise
      },
      borderColor: state.isFocused || state.ho ? '#4299e1' : '#4299e1',
      borderWidth: '2px',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#4299e1', // Blue placeholder color
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      backgroundColor: '#4299e1', // Blue indicator separator color
    }),
    multiValue: (provided) => ({
      ...provided,
      borderRadius: '9999rem', // Adjust the border radius for selected options
      backgroundColor: '#E9E9EF', // Background color for selected options
    }), 
    multiValueLabel: (provided) => ({
      ...provided,
      fontWeight: '500',
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color:'#f00',
      '&:hover': {
        backgroundColor: 'transparent', // Remove hover color
      },
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: state.isFocused ? '#4299e1' : '#4299e1', // Black when focused, blue otherwise
      '&:hover': {
        color: state.isFocused ? '#4299e1' : '#4299e1', // Maintain color when focused, blue otherwise
      },
    }),
  };

  return (
    <Select
      isMulti
      placeholder='Add-on'
      options={options}
      value={selected}
      onChange={handleChange}
      styles={customStyles}
    />
  );
};

export default MultiSelectDropdown;
