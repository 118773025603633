import React, { useEffect, useState } from 'react';
import SnekoLogo from '../../../assets/client/image/snekoLogoTrans.png';
import { auth, provider } from '../../../helper/firebase-config';
import { signInWithPopup } from 'firebase/auth';
import LoginPwd from './LoginPwd';
import SignIn from './SignIn';
import Navbar from '../Navbar';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import UserStorage from '../../../storage/UserStorage';



function Login() {
  const [_value, setValue] = useState('')
  const [_show, setShow] = useState(false)
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const randomFunction = async (data) => {

    const details = await axios.get(process.env.REACT_APP_URL + 'userDetails/getByEmail', { params: { email: data.user.email } });
    if (Object.keys(details.data).length === 0)
      navigate("/signup", { state: { emailSignIn: false, email: data.user.email } });

    UserStorage.Instance.Login({
      id: details.data.user_id,
      email: details.data.user_email,
      name: details.data.user_name,
      phone_number: details.data.user_phone_number,
      subUser: details.data.delivery_for
    })

    if (details.data.user_auth == 1 || details.data.user_auth == 3) {
      navigate("/");
    }
    else if (details.data.user_auth == 2) {
      await axios.post(process.env.REACT_APP_URL + 'userDetails/setUserDetailAuth', { user_id: details.data.user_id, user_auth: 3 });
      navigate("/");
    }
  }


  const handleClick = () => {

    signInWithPopup(auth, provider).then((data) => {
      randomFunction(data)
    })
      .catch((error) => {
        setErrorMessage('Popup Closed Unexpectedly')
        console.log('Caught error popup')
      })
  }

  useEffect(() => {
    setValue(localStorage.getItem('email'))
  })

  const [email, setEmail] = useState("")
  const [loading, setLoading] = useState(false)
  
  
  const sendEmail = async (e) => {
    e.preventDefault()
    setErrorMessage("")
    setLoading(true);
    if (!email) {
      setErrorMessage("Please Enter Email")
    }
    else {
      try {
        const details = await axios.get(process.env.REACT_APP_URL + 'userDetails/getByEmail', {params : { email: email }});
        if (Object.keys(details.data).length === 0) {
          const out = await axios.post(process.env.REACT_APP_URL + 'otp/generate-otp', { email: email });
          if (out.data.success === true) {
            navigate("/otp", { state: { email: email } });
          }
          setErrorMessage(out.data.message);
        }

        if (details.data.user_auth == 2 || details.data.user_auth == 3) {
          navigate("/pwd", { state: { email: email } });
        }
        else if (details.data.user_auth == 1) {
          navigate("/signup", { state: { emailSignIn: true, user_auth: details.data.user_auth, email: email, name: details.data.user_name, id: details.data.user_id, ph: details.data.user_phone_number } });
        }

      } catch (error) {
        setErrorMessage(error)
        console.error('Error during login:', error);
      }
    }
    setLoading(false);

  }

  return (
    <div>
      <Navbar />
      <div className='flex items-center justify-center mt-20'>

        <div className='bg-white w-5/6 md:w-3/4 lg:w-2/3 xl:w-[400px] 2xl:w-[450px] mt-1 mx-auto px-15 py-1 rounded-lg pb-10'>
          <div className='flex items-center justify-center'>
            <img className="sm:mx-auto sm:w-64 sm:max-w-sm w-16 md:w-32 lg:w-48 " src={SnekoLogo} alt="Logo"></img>
          </div>
          <div className="flex min-h-full flex-col justify-center px-3 py-1 lg:px-8">

            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
              <h2 className="mt-1 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">Sign-In to Sneko</h2>

            </div>

            <div className="mt-2 sm:mx-auto sm:w-full sm:max-w-sm">

              <div className="flex items-center justify-center">
                <button onClick={handleClick} className="w-80 px-4 py-2 items-center border flex justify-center gap-2 border-slate-200 dark:border-slate-700 rounded-lg text-slate-700 text-black hover:border-slate-400 hover:text-indigo-600  hover:shadow transition duration-150">
                  <img className="w-6 h-6 " src="https://www.svgrepo.com/show/475656/google-color.svg" loading="lazy" alt="google logo"></img>
                  <span>Continue with Google</span>
                </button>
              </div>
              <div className="flex items-center px-1 py-3 justify-between">
                <div className="w-full h-[1px] bg-gray-600"></div>
                <span className="text-sm uppercase mx-6 text-black">Or</span>
                <div className="w-full h-[1px] bg-gray-600"></div>
              </div>
              <form className="space-y-1" action="#" method="POST">

                <div className='flex justify-center'>
                  <div className="relative flex justify-center w-80 ">
                    <input type="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} className="block border border-gray-300 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-white rounded-lg  appearance-none focus:outline focus:ring-0  peer" placeholder="" />

                    <label htmlFor="email" className="absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 start-1 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">Email</label>
                  </div>
                </div>
                <div className='text-red-500 text-sm flex justify-center items-center'> {errorMessage}</div>
                <div className='flex justify-center col-start-1 col-end-4'>
                  <button type="submit" onClick={sendEmail} className="flex w-80 justify-center rounded-md text-white items-center bg-_blue px-3 py-1.5 text-base font-semibold leading-6 shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                    <span className={"animate-spin rounded-full h-4 w-4 border-t-4 border-_white-50 border-solid mr-4 " + (!loading && 'hidden')}></span>
                    Sign in</button>
                </div>

              </form>

            </div>
          </div>
        </div>
      </div>
    </div>
  )
}


export default Login
