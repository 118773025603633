// import React from "react";
import Compressor from 'compressorjs';
import axios from "axios";

const ImageUploader = (props) => {

    const setDetails = props.setDetails
    const setIsUploaded = props.setIsUploaded
    const dir = props.dir
    const propName = props.propName
    const uploadImage = async ({ file, onSuccess }) => {
        new Compressor(file, {
            quality: 0.6,
            maxHeight: 500,
            maxWidth: 500,
            success: async (compressedResult) => {
                try {
                    const formData = new FormData();
                    formData.append('image', compressedResult);
                    console.log('hi')
                    const response = await axios.post(process.env.REACT_APP_URL + "image" + "/upload", formData, { params: { directory: dir } });

                    // // Update the state here with the uploaded image data
                    setDetails(response.data, propName);
                    setIsUploaded(true)
                    return new Promise((resolve) => {
                        setTimeout(() => {
                            onSuccess(response);
                            resolve();
                        }, 0);
                    });
                } catch (error) {
                    console.log('Image upload failed:', error);
                }
            }
        })

    };
    const removeImage = async ({ url }) => {

        try {
            if (url !== "") {

                const response = await axios.delete(process.env.REACT_APP_URL + "image/delete", { params: { url: url } });
                if (response.status === 200) {
                    setIsUploaded(false)
                    setDetails('', 'restaurant_img_path'); // Clear the image path in your state
                } else {
                    console.log('Failed to delete image:', response);
                }
            }
            else {
                setIsUploaded(false)
            }
        } catch (error) {
            console.log('Error deleting image:', error);
        }
    }


    return { uploadImage, removeImage }
}
export default ImageUploader

