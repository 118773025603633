import React, { useEffect, useState } from 'react'
import SnekoLogo from '../../../assets/client/image/snekoLogoTrans.png'
import { auth } from '../../../helper/firebase-config'
import { signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";
import Navbar from '../Navbar';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import UserStorage from '../../../storage/UserStorage';


function LoginPwd() {

  const [loading, setLoading] = useState(false)
  const [loginPassword, setLoginPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState('');
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!location.state) {
      navigate("/");
    }
  }, [])

  const login = async (e) => {
    e.preventDefault()
    setLoading(true)
    if (loginPassword === " ") {
      setErrorMessage("Please Enter Password")
    }
    else {
      try {
        const user = await signInWithEmailAndPassword(auth, location.state.email, loginPassword)

        const details = await axios.get(process.env.REACT_APP_URL + 'userDetails/getByEmail', { params: { email: location.state?.email } });
        if (!details) {
          setErrorMessage('Error while Creating Account. Try again Later');
          return;
        }
        UserStorage.Instance.Login({
          id: details.data.user_id,
          email: details.data.user_email,
          name: details.data.user_name,
          phone_number: details.data.user_phone_number,
          subUser: details.data.delivery_for
        })
        navigate('/');
      } catch (error) {
        setErrorMessage('Invalid Credentials')
        console.log(error.message);
      }
    }

  setLoading(false)
  };

  const handleClick = (e) => {
    e.preventDefault()
    
    setLoading(false)
    sendPasswordResetEmail(auth, location.state.email).then(() => {
      navigate("/resetPwd");
    }).catch((error) => {
      console.log(error);
      setErrorMessage("Email is Invalid");
    })
    setLoading(false)
  }


  return (
    <div>
      <div className=' bg-white w-5/6 md:w-3/4 lg:w-2/3 xl:w-[400px] 2xl:w-[450px] mt-1 mx-auto px-15 py-1 rounded-lg mt-20 '>

        <div className='flex items-center justify-center'>
          <img className="sm:mx-64 sm:w-64 sm:max-w-sm w-16 md:w-32 lg:w-48 " src={SnekoLogo} alt="Logo"></img>
        </div>

        <div className="flex min-h-full flex-col justify-center px-3 py-1 lg:px-8">

          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <h2 className="mt-1 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">Please Verify password</h2>
            <p className='flex justify-center font-semibold'>
              {location.state?.email}
            </p>
          </div>

          <div className="mt-1 sm:mx-auto sm:w-full sm:max-w-sm">
            <form className="">
              <div className="mt-3 relative ">
                <input type="password" onChange={(event) => { setLoginPassword(event.target.value) }} id="password" pattern="[0-9]{10}" className="block border border-gray-300 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-white rounded-lg  appearance-none focus:outline focus:ring-0  peer" placeholder="" />
                <label htmlFor="password" className="absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 start-1 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">Password</label>
              </div>
              <div>
                <button onClick={handleClick} className="text-sm text-_blue hover:text-indigo-500">Forgot password?</button>
              </div>

              <div className='text-red-500 text-xs pt-1'>{errorMessage}</div>
              <div className='pt-7 pb-3 flex justify-center'>
                <button onClick={login} className="flex w-64 justify-center items-center rounded-md text-white bg-_blue px-3 py-1.5 text-base font-semibold leading-6 shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2">
                  <span className={"animate-spin rounded-full h-4 w-4 border-t-4 border-_white-50 border-solid mr-4 " + (!loading && ' hidden')}></span>
                  Login</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

  )
}

export default LoginPwd
