import { useState } from "react";
import FoodType from "../../other/FoodType";

const OrderDetailsItem = ({ item }) => {

  const initialImgSrc = item.item_img_path;
  const [imgSrc, setImgSrc] = useState(initialImgSrc);

  const handleImageError = () => {
    setImgSrc(require("../../../../assets/item.png"));
  };


  return (
    <div className="flex gap-x-5">
      <div className='basis-2/12 flex items-center'>
        <img
          className='w-full h-full md:h-auto object-cover block rounded-md aspect-square'
          src={imgSrc}
          alt='item'
          onError={handleImageError}
        />
      </div>
      <div className='basis-10/12 flex flex-cols items-center justify-between'>
        <div className='flex items-center text-md font-medium'>
          <FoodType />
          {item.qty} x {item.item_name}
        </div>
        <div className='my-2 space-x-1 font-medium'>
          ₹ {item.item_price * item.qty}
        </div>
      </div>
    </div>
  );
};

export default OrderDetailsItem;
