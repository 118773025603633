import React from "react";

import Navbar from "../../component/client/Navbar";
import RestaurantList from "../../component/client/restaurant/RestaurantList";
import useRestaurants from "../../hooks/useRestaurants";

const HomePage = () => {
    const {restaurants, isLoading, error} = useRestaurants();

    return (
        <div className='relative'>
            <Navbar />

            <div className='container-max'>
                <h1 className='my-4 mt-8 font-bold text-xl'>
                    Restaurants Available
                </h1>
                <RestaurantList isLoading={isLoading} restaurants={restaurants} error={error}/>
            </div>
            
        </div>
    );
};
export default HomePage;
