import React, { useState } from 'react'
import SnekoLogo from '../../../assets/client/image/snekoLogoTrans.png'
import Navbar from '../Navbar';

function ChangePassword() {

  return (
    <div>
      <Navbar />
      <div className='mt-20'>

        <div className='bg-white w-5/6 md:w-3/4 lg:w-2/3 xl:w-[400px] 2xl:w-[450px] mt-2 mx-auto px-10 py-1 pb-4 rounded-lg '>
          <div className='flex items-center justify-center'>
            <img className="sm:mx-auto sm:w-full sm:max-w-sm w-16 md:w-32 lg:w-48 " src={SnekoLogo} alt="Logo"></img>
          </div>
          <div className="flex min-h-full flex-col justify-center px-1  lg:px-8">

            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
              <h2 className="mt-1 text-center text-lg leading-9 tracking-tight text-gray-500">A link has been sent to your email. Please verify there.</h2>

            </div>


          </div>
        </div>
      </div>
    </div>
  )
}

export default ChangePassword;
