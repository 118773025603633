import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { faSearch, faShoppingBag, faLocationDot, faCheck, faChevronDown, faBars } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import snekoLogo from "../../assets/client/image/snekoLogoTrans.png";
import CartStorage from "../../storage/CartStorage";
import UserStorage from "../../storage/UserStorage";
import userData from "../../assets/client/data/tempUser.json"
import SlotTiming from "./other/Dropdown";
import { useMediaQuery } from 'react-responsive'

const Navbar = () => {
    const [_, setForceUpdate] = useState(0);
    const [isLocationMenuOpen, setIsLocationMenuOpen] = useState(false);
    const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
    const navigate = useNavigate();


    useEffect(() => {
        CartStorage.Instance.AddListener(setForceUpdate);
        UserStorage.Instance.AddListener(setForceUpdate);
        return () => {
            CartStorage.Instance.RemoveListener(setForceUpdate);
            UserStorage.Instance.RemoveListener(setForceUpdate);
        };
    }, []);

    // const Login = () => {
    //     UserStorage.Instance.Login(userData);
    // }
    const Logout = () => {
        UserStorage.Instance.Logout();
        navigate("/");
    }

    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const handleToggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };


    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 770px)' })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 769px)' })

    return (
        <header className='sticky w-full bg-_white-50 top-0 z-20 border-b drop-shadow-md border-gray-100'>
            <div className='container-max flex flex-col md:flex-row justify-between items-end md:items-center '>
                <div className='flex justify-between items-center w-full md:w-auto '>
                    <Link
                        to='/'
                        className='text-l md:text-2xl font-semibold flex items-center '
                    >
                        <img src={snekoLogo} alt="logo" className="md:w-20 w-16" />
                        <span className='md:text-lg text-sm'>Sneko</span>
                    </Link>
                    
                    {isTabletOrMobile &&
                    <div className="flex ">
                        <Link
                                to='/cart'
                                className='p-2 mr-4 relative md:px-4 hover:text-_blue rounded-md flex items-center gap-2 text-zinc-700'
                            >
                                <FontAwesomeIcon icon={faShoppingBag} size="sm" />
                                <p className='md:block text-sm'>Cart</p>
                                {CartStorage.Instance.itemCount !== 0 && (
                                    <p className='absolute -top-1 -right-1 bg-_blue text-white flex justify-center items-center w-5 h-5 text-xs rounded-full'>
                                        {CartStorage.Instance.itemCount}
                                    </p>
                                )}
                            </Link>
                    <button onClick={handleToggleSidebar} className="md:hidden pr-5 focus:outline-none">
                        ☰
                    </button>
                    </div>
                    }
                </div>

                {isDesktopOrLaptop ?
                    <div className="isDesktopOrLaptop md:w-auto flex flex-col md:flex-row gap-2  items:start md:items-end w-1/2 bg-_white-50  p-2  md:drop-shadow-none drop-shadow-lg md:p-0 rounded-md md:rounded-none md:items-center">
                        <div className="relative group pr-3">
                            <div
                                onClick={() => setIsLocationMenuOpen(!isLocationMenuOpen)}
                                className="flex cursor-pointer py-2 md:pl-4 pl-0 justify-between items-center gap-0 md:gap-4"
                            >
                                <div>
                                    <span className='text-xl text-_blue pr-3'>
                                        <FontAwesomeIcon icon={faLocationDot} size="sm" />
                                    </span>
                                    <span className=' text-gray-600 text-sm md:font-base'>Hyderabad</span>
                                </div>
                                <div><FontAwesomeIcon icon={faChevronDown} size="sm" /></div>
                            </div>
                            {isLocationMenuOpen &&
                                <div className="absolute bg-gray-100 z-10 border border-gray-300 rounded-md">
                                    <div
                                        onClick={() => setIsLocationMenuOpen(false)}
                                        className={'block flex justify-between items-center cursor-pointer gap-5 md:gap-10 px-4 w-full justify-center py-2 hover:bg-blue-200 text-_blue '} >
                                        <div className=" max-w-full whitespace-nowrap text-sm ">Hyderabad</div>
                                        <FontAwesomeIcon icon={faCheck} />
                                    </div>
                                </div>
                            }
                        </div>

                        
                        {/* <label htmlFor="SearchBox" className="text-gray-400 pl-3 pr-3 py-2">
                            <FontAwesomeIcon icon={faSearch} />
                        </label>
                        <input
                            type='search'
                            name='search'
                            id='SearchBox'
                            placeholder='Search for Restaurants'
                            className='bg-_white-50 text-base outline-none py-2 pr-4'
                        /> */}

                        {/* Other menu items go here */}

                        <Link
                            to='/cart'
                            className='p-2 relative md:px-4 hover:text-_blue rounded-md flex items-center gap-2 text-zinc-700'
                        >
                            <FontAwesomeIcon icon={faShoppingBag} size="sm" />
                            <p className='md:block text-sm'>Cart</p>
                            {CartStorage.Instance.itemCount !== 0 && (
                                <p className='absolute -top-1 -right-1 bg-_blue text-white flex justify-center items-center w-5 h-5 text-xs rounded-full'>
                                    {CartStorage.Instance.itemCount}
                                </p>
                            )}
                        </Link>

                        {UserStorage.Instance.auth ? (
                            <div className="relative group">
                                <button className="md:ml-4  bg-_blue text-_white-200 p-1 px-3 md:p-2 md:px-3.5 items-center gap-2 md:flex rounded-full">
                                    {UserStorage.Instance.name[0] || '-'}
                                </button>
                                <div className="absolute hidden bg-white border border-gray-300 rounded-md w-30 md:w-40 top-10 left-1 md:right-0 group-hover:block">
                                    <div className='cursor-default block text-sm md:text-md px-4 w-full justify-center py-2 border-b font-semibold text-gray-800 whitespace-nowrap'>
                                        {UserStorage.Instance.name}
                                    </div>
                                    <Link to={`/profile`}
                                        className='block text-sm  w-full justify-center px-4 py-2 text-gray-800 border-t hover:bg-blue-200'>
                                        Profile
                                    </Link>
                                    <Link to={`/orderHistory/${UserStorage.Instance.id}`}
                                        className='block text-sm  w-full justify-center px-4 py-2 text-gray-800 hover:bg-blue-200 border-b'>
                                        Order History
                                    </Link>
                                    <div onClick={() => Logout()}
                                        className='block text-sm cursor-pointer px-4 w-full justify-center py-2 border-t text-gray-800 hover:bg-blue-200'>
                                        Logout
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <Link to={'/login'}>
                                <div
                                    className='cursor-pointer md:ml-4 ml-0 bg-_blue text-_white-200 p-2 text-sm px-4 rounded-md items-center md:gap-2 gap-0 md:flex'>
                                    Login
                                </div>
                            </Link>
                        )}
                    </div> : null}

                {isSidebarOpen && isTabletOrMobile ?
                    <div className="absolute top-20  right-5 bg-gray-100 z-10 border border-gray-300 rounded-md right-0 ">
                        <div className="w-1/2 flex flex-col gap-2  items:start w-full bg-_white-50  p-2  drop-shadow-lg rounded-md md:rounded-none md:items-center">
                            <div className="relative group pr-5">
                                <div
                                    onClick={() => setIsLocationMenuOpen(!isLocationMenuOpen)}
                                    className="flex cursor-pointer py-2 md:pl-4 pl-0 justify-between items-center gap-0 md:gap-4"
                                >
                                    <div>
                                        <span className='text-xl text-_blue pr-3'>
                                            <FontAwesomeIcon icon={faLocationDot} size="sm" />
                                        </span>
                                        <span className=' text-gray-600 text-sm '>Hyderabad</span>
                                    </div>
                                    <div><FontAwesomeIcon icon={faChevronDown} size="sm" className="pl-1" /></div>
                                </div>
                                {isLocationMenuOpen &&
                                    <div className="absolute left-0 bg-gray-100 z-10 border border-gray-300 rounded-md">
                                        <div
                                            onClick={() => setIsLocationMenuOpen(false)}
                                            className={'block flex justify-between items-center cursor-pointer gap-4 px-4 w-full justify-center py-2 hover:bg-blue-200 text-_blue'} >
                                            <div className=" max-w-full whitespace-nowrap text-sm ">Hyderabad</div>
                                            <FontAwesomeIcon icon={faCheck} />
                                        </div>
                                    </div>
                                }

                            </div>

                            {/* Other menu items go here */}


                            {UserStorage.Instance.auth ? (
                                <div className="relative group border-t">
                                    {/* <button className="md:ml-4  bg-_blue text-_white-200 p-1 px-3 md:p-2 md:px-3.5 items-center gap-2 md:flex rounded-full">
                                        {UserStorage.Instance.name[0] || '-'}
                                    </button> */}
                                    {/* <div className="absolute hidden bg-white border border-gray-300 rounded-md w-30 md:w-40 top-10 left-1 md:right-0 group-hover:block"> */}
                                        <div className='cursor-default block text-sm md:text-md px-4 w-full justify-center py-2 border-b font-semibold text-gray-800 whitespace-nowrap'>
                                            {UserStorage.Instance.name}
                                        </div>
                                        <Link to={`/profile`}
                                            className='block text-sm  w-full justify-center px-4 py-2 text-gray-800 border-t hover:bg-blue-200'>
                                            Profile
                                        </Link>
                                        <Link to={`/orderHistory/${UserStorage.Instance.id}`}
                                            className='block text-sm  w-full justify-center px-4 py-2 text-gray-800 hover:bg-blue-200 border-b'>
                                            Order History
                                        </Link>
                                        <div onClick={() => Logout()}
                                            className='block text-sm cursor-pointer px-4 w-full justify-center py-2 border-t text-gray-800 hover:bg-blue-200'>
                                            Logout
                                        </div>
                                    {/* </div> */}
                                </div>
                            ) : (
                                <Link to={'/login'}>
                                    <div
                                        className='cursor-pointer md:ml-4 ml-0 bg-_blue text-_white-200 p-2 text-sm px-4 rounded-md items-center md:gap-2 gap-0 md:flex'>
                                        Login
                                    </div>
                                </Link>
                            )}
                        </div>
                    </div> : null
                }
            </div>
        </header>

    );
};
export default Navbar;