import React from "react";

const sizeClasses = {
  poppinsMediumHeading: "text-2xl font-medium font-poppins",
  poppinsMediumSubHeading: " text-xl font-medium font-poppins",
  poppinsMediumDecription: "text-sm font-medium font-poppins",

  poppinsRegularHeading: "text-2xl font-normal font-poppins",
  poppinsRegularSubHeading: " text-xl font-normal font-poppins",
  poppinsRegularDecription: "text-sm font-normal font-poppins",

  poppinsBoldHeading: "text-2xl font-bold font-poppins",
  poppinsBoldSubHeading: " text-xl font-bold font-poppins",
  poppinsBoldDecription: "text-sm font-bold	 font-poppins",

  poppinsSemiBoldHeading: "text-xl font-semibold font-poppins",
  poppinsSemiBoldSubHeading: "text-xl	 font-semibold	font-poppins",
  poppinsSemiBoldDecription: " text-sm font-semibold font-poppins",

};

const Text = ({ children, className = "", size, comp='', ...restProps }) => {
  const Component = comp || "span";
  const textColorLight = {
    color: 'var(--color-black-50)'
  }
  //eslint-disable-next-line
  const textColorDark = {
    color: 'var(--color-black-150)'
  }
  return (
    <Component
      style={textColorLight}
      className={`text-left ${className} ${sizeClasses[size]}`}
      // className={`text-left ${className} ${size && sizeClasses[size]}`}
      {...restProps}
    >
      {children}
    </Component>
  );
};

export { Text };
