import React, { useEffect, useState } from 'react';
import { Text } from '../../component/admin/Text';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot, faPhone, faTrash } from "@fortawesome/free-solid-svg-icons";
// import dominosLogo from "../../assets/admin/image/Dominos_pizza_logo.png"
import veg from "../../assets/admin/svg/veg-icon.svg";
import nonVeg from "../../assets/admin/svg/nonveg-icon.svg";
import jain from "../../assets/admin/svg/jain-icon.svg";
// import restaurantPlaceHolder from "../../assets/restaurant.svg"
import itemSvg from "../../assets/item.svg"
import restaurantSvg from "../../assets/restaurant.svg"
import "../../styles/admin/mainPage.css";

import { Avatar, Divider } from 'antd';
import { dataRetrieverById, updatePartObject } from './BackendConnector';

import upiScreenShotUploader from "../../component/client/upiScreenShotUploader";

const AdminDisplayOrder = (props) => {
    const [orderDetails, setOrderDetails] = useState(null)

    useEffect(() => {
        const dataRetriever = async () => {
            const response = await dataRetrieverById("order", props.orderId);
            if (response) {
                setOrderDetails(response)
            }
        }
        dataRetriever()
        //eslint-disable-next-line
    }, [])

    const CustomAvatar = ({ img, defaultImg, ...otherProps }) => {
        const [avatarSrc, setAvatarSrc] = useState(img);

        const handleError = () => {
            setAvatarSrc(defaultImg);
        };

        return <Avatar {...otherProps} src={avatarSrc} onError={handleError} />;
    };


    useEffect(() => {
        const updateOrder = async () => {
            if (orderDetails?.invoice_id === 'Reference ID Checked') {
                console.log('yes')
                await updatePartObject('order/updateOrder', orderDetails.order_id, 'Reference ID Checked', 'invoice_id')
            }
        }
        updateOrder()
        //eslint-disbale-next-line
    }, [orderDetails?.invoice_id])

    const { removeImage } = upiScreenShotUploader({ setOrderDetails });

    const orderRenderer = () => {
        return (
            <>
                {orderDetails && (
                    <>
                        {orderDetails.order_item.map((order) => (
                            <div key={order.id}>
                                <div className='flex items-center justify-start'>


                                    <CustomAvatar
                                        img={order?.restaurant_img_path}
                                        defaultImg={restaurantSvg}
                                        shape="square"
                                        size={{
                                            md: 32,
                                            lg: 40,
                                            xl: 60,
                                            xxl: 100,
                                        }}
                                        className="mr-2"
                                    />
                                    <Text size={'poppinsMediumSubHeading'}>
                                        {order?.restaurant_name}
                                    </Text>
                                </div>
                                {order.items.map((item) => (
                                    <div className='flex justify-between px-10 py-3' key={item.id}>
                                        <div className={`flex ${item?.add_ons.length > 0 ? "items-start" : "items-center"}`}>
                                            <CustomAvatar
                                                img={item?.item_type === 'veg' ? veg : item?.item_type === 'jain' ? jain : nonVeg}
                                                defaultImg={itemSvg}
                                                shape="square"
                                                size={{
                                                    md: 10,
                                                    lg: 10,
                                                    xl: 16,
                                                    xxl: 40,
                                                }}
                                                className="mr-2"
                                            />
                                            <div className='flex flex-col justify-end'>
                                                <Text size={'poppinsRegularDecription'}>
                                                    {item?.qty} x {item?.item_name}
                                                </Text>
                                                <>
                                                    {item?.add_ons.length > 0 ? (
                                                        <>
                                                            <Text className=' text-xs font-semibold font-poppins'>
                                                                Add ons:
                                                            </Text>
                                                            {item?.add_ons.map((addon) => {
                                                                return (

                                                                    <Text className=' text-xs font-normal font-poppins mx-5'>
                                                                        {addon?.type}
                                                                    </Text>
                                                                )
                                                            })
                                                            }

                                                        </>
                                                    ) : null}
                                                </>
                                            </div>
                                        </div>
                                        <Text size={"poppinsSemiBoldDecription"}>
                                            {item?.item_price * item.qty}
                                        </Text>
                                    </div>
                                ))}
                                <Divider className="wide-dashes" />
                            </div>
                        ))}
                    </>
                )}
            </>
        );
    }


    return (
        <div>
            {orderDetails && (
                <div className='sticky top-0 h-1/3 z-50 bg-_white-150 px-3 py-2 rounded-lg'>
                    <div className='flex justify-between m-2'>
                        <Text size="poppinsMediumHeading">
                            {orderDetails?.order_for?.name ?? "Customer Name"}
                        </Text>

                        <Text size="poppinsMediumHeading">
                            ₹ {orderDetails?.order_total ?? "Order Total"}
                        </Text>
                    </div>

                    <div className='flex justify-start m-2'>
                        <div className='mr-3'>
                            <FontAwesomeIcon icon={faPhone} className='text-black-100 mr-1' size='sm' />
                            <Text size='poppinsMediumDecription'>
                                {orderDetails?.order_for?.phone_number ?? "Phone Number"}
                            </Text>
                        </div>
                        <div className='mx-3'>
                            <FontAwesomeIcon icon={faLocationDot} className='text-black-100 mr-1' size='sm' />
                            <Text size='poppinsMediumDecription'>
                                {orderDetails?.pick_up_point ?? "Pickup Point"}
                            </Text>
                        </div>
                    </div>

                    <div className='flex justify-between m-2'>
                        <div>
                            <a target='_blank' href={orderDetails?.invoice_id} className='mr-2'>
                                {orderDetails?.invoice_id ?? "Invoice Id"}
                            </a>
                            {
                                orderDetails?.invoice_id !== 'Reference ID Checked' ?
                                    (<FontAwesomeIcon icon={faTrash} className="hover:cursor-pointer text-_blue" onClick={() => removeImage({ url: orderDetails?.invoice_id })} />) : null
                            }
                        </div>

                        <Text size='poppinsMediumDecription' className={`text-${orderDetails?.paid ? 'green' : 'red'}`}>
                            {orderDetails?.paid ? "PAID" : "NOT PAID"}
                        </Text>
                    </div>
                </div>
            )}

            <div className='h-2/3 z-100 relative my-3 overflow-x-auto'>
                {orderRenderer()}
            </div>
        </div>

    )
}
export default AdminDisplayOrder