import { useState } from "react";
import DisplayError from "../../component/client/DisplayError";
import Navbar from "../../component/client/Navbar";
import Cart from "../../component/client/cart/Cart";
import CartContext from "../../context/CartContext";
import CartStorage from "../../storage/CartStorage";
import OrderSummary  from "../../component/client/cart/OrderSummary";

const CartPage = () => {
  const [_, setForceUpdate] = useState(0);

  return (
    <div className='relative'>
      <Navbar/>
      <div className='container-max py-4 pb-16'>
        <h1 className='text-2xl my-4 font-semibold'>Cart</h1>
        
        { CartStorage.Instance.itemCount === 0 ? (
          <DisplayError description={"Your cart is empty!"}/>
          ) : (
            <div className='min-h-[60vh] pb-8 md:flex gap-8'>
              <CartContext.Provider value={{cart: CartStorage.Instance, setForceUpdate: setForceUpdate}}>
                <Cart/>
                <OrderSummary/>
              </CartContext.Provider>
            </div>
          ) 
        }
      </div>
    </div>
  );
};

export default CartPage;
