import axios from 'axios';
import { useEffect, useState } from 'react';

import localData from "../assets/client/data/tempOrder.json"

const useOrderHistory = ({ id, orderID }) => {
  const [orderDetail, setOrderDetail] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const getOrderHistory = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const { data } = await axios.get(process.env.REACT_APP_URL + 'order/getUserOrderHistory', { params: { user_id: id } });

      await new Promise(r => setTimeout(r, 500));
      if (Object.keys(data).length != 0)
        setOrderDetail(data);
      else
        setError("No Order History");
    } catch (err) {
      console.log(err.response);
      setError(err.response);
    } finally {
      setIsLoading(false);
    }
  };

  const getOrderDetail = async (orderID) => {
    setIsLoading(true);
    setError(null);

    try {
      console.log("re");
      const { data } = await axios.get(process.env.REACT_APP_URL + 'order/getById', { params: { id: orderID } });

      await new Promise(r => setTimeout(r, 500));
      if (Object.keys(data).length != 0)
        setOrderDetail(data);
      else
        setError("Order Detail not Avaiable!");
    } catch (err) {
      console.log(err.response);
      setError(err.response);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (orderID)
      getOrderDetail(orderID);
    else
      getOrderHistory();
  }, [])

  return { orderDetail, isLoading, error };
};

export default useOrderHistory;
