import { useState } from 'react';

import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RestaurantMenuItem from './RestaurantMenuItem';

const RestaurantMenuCategory = ({ category }) => {
  const [itemsActive, setItemsActive] = useState(false);

  return (
    <div className={"my-4 rounded-md " + (itemsActive ? 'shadow-lg' : 'shadow')}>
      <div
        onClick={() => setItemsActive(!itemsActive)}
        className={'flex cursor-pointer justify-between items-center p-4 rounded-md select-none ' + (itemsActive ? 'bg-_white-125' : 'bg-_white-100')}
      >
        <h3 className='text-lg font-semibold'>{category.category}</h3>
        <div>
          {itemsActive ? (
            <FontAwesomeIcon icon={faChevronUp} />
          ) : (
            <FontAwesomeIcon icon={faChevronDown} />
          )}
        </div>
      </div>

      {itemsActive && (
        <ul className='px-4 pt-4'>
          {category?.items.map((item, i) => (
            item.available &&
            <li className='px-2 pt-2 pb-8 flex mb-4 gap-4 md:gap-8 justify-between items-center border-b'
              key={i}
            >
              <RestaurantMenuItem item={item} />
            </li>
          ))}
        </ul>
      )
      }


    </div>
  );
};

export default RestaurantMenuCategory;
