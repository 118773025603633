import { createContext } from "react";
import { useParams } from 'react-router-dom';

import Navbar from '../../component/client/Navbar';
import ShimmerRestaurantMenu from '../../component/client/shimmer/ShimmerRestaurantMenu';
import useRestaurantsMenu from '../../hooks/useRestaurantsMenu';
import RestaurantMenu from '../../component/client/restaurant/RestaurantMenu';
import RestaurantInfo from '../../component/client/restaurant/RestaurantInfo';
import DisplayError from '../../component/client/DisplayError';
import RestaurantContext from "../../context/RestaurantContext";

const RestaurantPage = () => {
  const { id } = useParams();
  const { restaurantMenu, isLoading, error } = useRestaurantsMenu(id);

  return (
    <div className='relative'>
      <Navbar/>
      <div className='container-md my-8'>
        { error ? (
          <DisplayError title={"Error"} description={error}/>
          ) : isLoading ? (
            <ShimmerRestaurantMenu />
            ) : (
              <  RestaurantContext.Provider value={restaurantMenu}>
                <RestaurantInfo/>
                <RestaurantMenu/>
              </  RestaurantContext.Provider>
            )
        }
      </div>
    </div>
  );
};
export default RestaurantPage;
