import { useContext } from "react";
import RestaurantContext from "../../../context/RestaurantContext";

const RestaurantInfo = () => {
  const { restaurant_name, cuisines } = useContext(RestaurantContext);
  
  return (
    <div className='flex justify-between items-center pb-4 border-b-2 border-dashed'>
      <div>
        <h2 className='text-2xl font-bold my-2'>{restaurant_name}</h2>
        <p className='text-base text-gray-500'>
        </p>
        <p className='text-sm text-gray-500'>
          {cuisines?.map((c, i) => (
            <span key={i}>
              {c}
              {i === cuisines.length - 1 ? '' : ', '}
            </span>
          ))}
        </p>
      </div>
    </div>
  );
};
export default RestaurantInfo;
