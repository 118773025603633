import axios from 'axios';
import React, { useEffect, useState } from 'react'
import SnekoLogo from '../../../assets/client/image/snekoLogoTrans.png'
import ChangePassword from './ChangePassword';
import { useLocation, useNavigate } from 'react-router-dom';

function OtpAuth() {
    const location = useLocation();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('')
    const [otp, setOtp] = useState('')
    const [msg, setMsg] = useState('')

    useEffect(() => {
        if (!location.state) {
            navigate("/");
        }
    }, [])

    const resend = async () => {
        setErrorMsg('')
        setLoading(true)
        const out = await axios.post(process.env.REACT_APP_URL + 'otp/resend-otp', { email: location.state.email });
        setMsg('OTP sended again')
        setLoading(false)
    }

    const verify = async (e) => {
        e.preventDefault()
        setLoading(true)
        setMsg('');
        try{
            const out = await axios.post(process.env.REACT_APP_URL + 'otp/verify-otp', { email: location.state.email, otp: otp });
            if (out.data.success === true) {
                navigate("/signup", { state: { emailSignIn: true, email: location.state.email } });
            }
            setErrorMsg(out.data.message);
        }catch(e){
            if(e.response.status === 401){
                setErrorMsg("Invalid OTP");
            }
            setErrorMsg("Error verifing OTP. Try again.");
        }
        setLoading(false)
    }
    
    return (
        <div>
            <div className='bg-white w-5/6 md:w-3/4 lg:w-2/3 xl:w-[400px] 2xl:w-[450px] mt-1 mx-auto px-15 py-8 rounded-lg '>
                <div className='flex items-center justify-center'>
                    <img className="sm:mx-64 sm:w-64 sm:max-w-sm w-16 md:w-32 lg:w-48 " src={SnekoLogo} alt="Your Company"></img>
                </div>
                <div className="sm:mx-auto sm:w-full sm:max-w-sm">

                    <h2 className="mt-2 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">Verify Email</h2>
                    <p className='text-center text-xl leading-9 tracking-tight'>OTP has been sent to your email</p>
                </div>

                <div className="max-w-md mx-auto  max-w-sm mt-1 rounded">
                    <form className=" px-4 py-6">
                        <div className="mt-3 relative ">
                            <input type="text" onChange={(event) => setOtp(event.target.value)} id="text" className="block border border-gray-300 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-white rounded-lg  appearance-none focus:outline focus:ring-0  peer" placeholder="" />
                            <label htmlFor="text" className="absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 start-1 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">OTP</label>
                        </div>
                        <div className="text-sm px-3 cursor-pointer">
                            <div onClick={resend} className="font-semibold text-_blue hover:text-_blue">Resend OTP</div>
                        </div>
                        <div className="flex text-sm">
                            {msg}
                        </div>
                        <div className="pt-5 mt-4 flex items-center justify-center">
                            <button type='submit' onClick={verify} className="flex justify-center items-center bg-_blue text-white font-bold py-2 px-20 rounded focus:outline-none focus:shadow-outline" >
                             <span className={"animate-spin rounded-full h-4 w-4 border-t-4 border-_white-50 border-solid mr-4 " + (!loading && ' hidden')}></span>
                                Verify
                            </button>
                        </div>
                    </form>
                </div>

            </div>
        </div>

    )
}

export default OtpAuth
