
import axios from "axios";


export async function dataSender(data) {

    try {
        const response = await axios.post(process.env.REACT_APP_URL + "restaurant" + "/add", data);
        return response.data;
    } catch (e) {
        console.log(e, "Error Adding Restaurant")
    }


}

export async function dataRetriever(collectionName) {

    try {
        const response = await axios.get(process.env.REACT_APP_URL + collectionName + "/get")
        return response.data
    } catch (e) {
        console.log(e, `Error Fetching ${collectionName}`)
    }
}

export async function dataRetrieverById(collectionName, id) {

    try {
        const response = await axios.get(process.env.REACT_APP_URL + collectionName + "/getById", { params: { id: id } })
        return response.data
    } catch (e) {
        console.log(e, `Error Fetching ${collectionName}`)
    }
}

export async function dataSenderById(collectionName, id, category, data) {
    try {
        const response = await axios.put(process.env.REACT_APP_URL + collectionName + "/addItems", { params: { restaurantId: id, category: category, newItem: data } });
        return response.data
    } catch (error) {
        console.log(error, `Error Fetching ${collectionName}`)
    }
}

export async function deleteItemData(collectionName, id, categoryIndex, itemIndex) {

    try {
        const response = await axios.delete(process.env.REACT_APP_URL + collectionName + "/deleteItems", { params: { restaurantId: id, catIndex: categoryIndex, itmIndex: itemIndex } });
        return response.data
    } catch (error) {
        console.log("Error Deleting Item")
    }

}
export async function deleteCategory(collectionName, id, category) {

    try {
        const response = await axios.delete(process.env.REACT_APP_URL + collectionName + "/deleteCategory", { params: { id: id, category: category } });
        return response.data
    } catch (error) {
        console.log("Error Deleting Category");
    }

}

export async function updateItem(collectionName, id, data, category) {
    try {
        const response = await axios.patch(process.env.REACT_APP_URL + collectionName + "/updateItems", { params: { id: id, data: data, category: category } });
        return response.data
    } catch (error) {
        console.log(`Error Updating ${collectionName}`)
    }
}

export async function updatePartObject(path, id, value, propName) {
    try {
        const response = await axios.patch(process.env.REACT_APP_URL + path, { params: { id: id, data: value, propName: propName } });
        return response.data
    } catch (error) {
        console.log(`Error Updating ${path}`)
    }

}
export async function updateObject(path, value) {


    try {
        const response = await axios.patch(process.env.REACT_APP_URL + path, { params: { data: value } });
        return response.data
    } catch (error) {
        console.log(`Error Updating ${path}`)
    }

}

export async function partialOrderData(collectionName) {
    const response = await axios.get(process.env.REACT_APP_URL + collectionName + '/getPartialOrder')
    return response.data

}


export async function notficationHandler(email, name, orderID, pickUp, time) {
    const response = await axios.post(process.env.REACT_APP_URL + 'email/notificationMail', { email: email, name: name, orderID: orderID, pickUp: pickUp, time: time })
    return response.data
}


export async function dateWiseData(collectionName, date) {

    let Date = date.getDate()
    let Month = date.getMonth()
    let Year = date.getFullYear()
    const format = Date + "/" + Month + "/" + Year
    // console.log(format)
    const response = await axios.get(process.env.REACT_APP_URL + collectionName + "/dateWiseData", { params: { date: format } })
    return response.data
}
export async function getOrderByStatus(collectionName, status1, status2 = "", date) {
    if (date) {
        let Date = date.getDate()
        let Month = date.getMonth()
        let Year = date.getFullYear()
        let format = Date + "/" + Month + "/" + Year
        // console.log(format)
        const response = await axios.get(process.env.REACT_APP_URL + collectionName, { params: { status1: status1, status2: status2, date: format } })
        return response.data
    }
    const response = await axios.get(process.env.REACT_APP_URL + collectionName, { params: { status1: status1, status2: status2, date: null } })
    return response.data

}
