import OrderDetailsRestaurant from "./OrderDetailsRestaurant";

const OrderDetails = ({orderDetail}) => {
  return (
    <ul className='basis-7/12'>
      { orderDetail.order_item.map((restaurant, i) => (
        <OrderDetailsRestaurant restaurant={restaurant} key={i}/>
      ))}
  </ul>
  );
};

export default OrderDetails;
