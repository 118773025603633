import React, { useEffect, useState } from 'react';
import SnekoLogo from '../../../assets/client/image/snekoLogoTrans.png';
import { auth } from '../../../helper/firebase-config';
import { sendPasswordResetEmail } from "firebase/auth";
import Navbar from '../Navbar';
import { useNavigate } from 'react-router-dom';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UserStorage from '../../../storage/UserStorage';
import { async } from '@firebase/util';

function UserProfile() {

  const [loading, setLoading] = useState(false)
  const [isOtherMenu, setIsOtherMenu] = useState(false);
  const [isAddFormOpen, setIsAddFormOpen] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [name, setName] = useState(UserStorage.Instance.name);
  const [ph, setPh] = useState(UserStorage.Instance.phone_number);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (!UserStorage.Instance.auth) {
      navigate("/");
    }
  }, [])

  const [editFormData, setEditFormData] = useState({
    id: '',
    name: '',
    phone_number: '',
  });

  const [addFormData, setAddFormData] = useState({
    name: '',
    phone_number: '',
  });


  const handleCancelAddForm = () => {
    setAddFormData({
      name: '',
      phone_number: '',
    });
    setError('');
    setIsAddFormOpen(false);
  };

  const handleCancelEditForm = () => {
    setEditFormData({
      id: '',
      name: '',
      phone_number: '',
    });
    setIsAddFormOpen(false);
    setIsEditFormOpen(false); // Hide Add form when editing
    setError('');

  };

  const handleEditClick = (accountId) => {
    // const selectedSubAccount = subAccounts.find((subAccount) => subAccount.id === accountId);
    setEditFormData({
      id: accountId,
      name: UserStorage.Instance.subUser[accountId].name,
      phone_number: UserStorage.Instance.subUser[accountId].phone_number,
    });
    setIsAddFormOpen(false); // Hide Add form when editing
    setIsEditFormOpen(true); // Hide Add form when editing
  };

  const handleEditFormSubmit = (e) => {
    e.preventDefault();

    console.log(editFormData)

    if (editFormData.name.length === 0 || editFormData.phone_number.length === 0) {
      setError('All Fields are mandatory');
      return;
    }
    if (Number.isNaN(editFormData.phone_number) || editFormData.phone_number.length !== 10) {
      setError('Phone number should be 10 digits');
      return;
    }

    UserStorage.Instance.SetSubUser({
      name: editFormData.name,
      phone_number: editFormData.phone_number
    }, editFormData.id)

    setEditFormData({
      id: '',
      name: '',
      phone_number: '',
    });
    setError('');
  };

  const handleAddFormChange = (e) => {
    setAddFormData({
      ...addFormData,
      [e.target.name]: e.target.value,
    });
  };

  const handleAddFormSubmit = (e) => {
    e.preventDefault();

    if (addFormData.name.length === 0 || addFormData.phone_number.length === 0) {
      setError('All Fields are mandatory');
      return;
    }
    if (Number.isNaN(addFormData.phone_number) || addFormData.phone_number.length !== 10) {
      setError('Phone number should be 10 digits');
      return;
    }

    UserStorage.Instance.AddSubUser({
      name: addFormData.name,
      phone_number: addFormData.phone_number
    })

    setAddFormData({
      name: '',
      phone_number: '',
    });
    setError('');

    // Close the Add form after submission
    setIsAddFormOpen(false);
  };

  const handleClick = (e) => {
    e.preventDefault()

    sendPasswordResetEmail(auth, "email").then(() => {
      console.log('success');
      navigate("/resetPwd");
    }).catch((error) => {
      console.log(error);
    })
  }

  const ChangePassword = (e) => {
    e.preventDefault()
    setLoading(true)
    sendPasswordResetEmail(auth, UserStorage.Instance.email).then(() => {
      navigate("/resetPwd");
    }).catch((error) => {
      console.log(error);
    })
    setLoading(false)
  }
  const Save = async (e) => {
    e.preventDefault()
    setLoading(true)
    UserStorage.Instance.ChangeDetails(name, ph)
    navigate("/");
    setLoading(false)
  }

  return (
    <div>
      <Navbar />
      <div className="bg-white flex flex-col bg-white w-full md:w-3/4 lg:w-3/5 xl:w-2/5 2xl:w-2/5 mt-5 mx-auto px-4 sm:px-8 py-8 rounded-lg ">
        <div className="container max-w-screen-md mx-auto flex-1 flex flex-col items-center justify-center px-2">
          <div>
            <img className="sm:mx-auto sm:w-32 sm:max-w-sm w- md:w-60 lg:w-48" src={SnekoLogo} alt="Your Company" />
          </div>
          <div className="bg-transparent px-4 sm:px-6 lg:px-8 py-2 rounded-xl text-black w-full">
            <h1 className="text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">Profile</h1>
            <div className="mt-4 relative">
              <input
                type="text"
                id="full_name"
                defaultValue={UserStorage.Instance.name}
                onChange={(e) => setName(e.target.value)}
                className="block border border-gray-300 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-white rounded-lg appearance-none focus:outline focus:ring-0 peer"
                placeholder="Full Name"
              />
              <label
                htmlFor="full_name"
                className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 start-1 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto"
              >
                Full Name
              </label>
            </div>

            <div className="mt-4 relative">
              <input
                type="email"
                id="email"
                defaultValue={UserStorage.Instance.email}
                disabled={true}
                className="block border border-gray-300 px-2.5 pb-2.5 bg-gray-200 pt-4 w-full text-sm text-gray-900 rounded-lg appearance-none focus:outline focus:ring-0 peer"
                placeholder="Email"
              />
              <label
                htmlFor="email"
                className="absolute bg-gradient-to-t from-gray-200 to-white text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 start-1 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto"
              >
                Email
              </label>
            </div>

            <div className="mt-4 relative">
              <input
                type="number"
                pattern="[0-9]{10}"
                defaultValue={UserStorage.Instance.phone_number}
                onChange={(e) => setPh(e.target.value)}
                id="phone_number"
                className="block border border-gray-300 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-white rounded-lg appearance-none focus:outline focus:ring-0 peer"
                placeholder="Phone Number"
              />
              <label
                htmlFor="phone_number"
                className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 start-1 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto"
              >
                Phone Number
              </label>
            </div>

            {/* <div
              onClick={() => setIsOtherMenu(!isOtherMenu)}
              className={'flex cursor-pointer justify-between items-center mt-6 p-4 rounded-md select-none bg-gray-200'}
            >
              <h3 className='text-md font-medium'>Delivery for Other</h3>
              <div>
                {isOtherMenu ? (
                  <FontAwesomeIcon icon={faChevronUp} />
                ) : (
                  <FontAwesomeIcon icon={faChevronDown} />
                )}
              </div>
            </div>

            {isOtherMenu && (
              <div className='p-4 border border-2 rounded-md'>
                {UserStorage.Instance.subUser.map((subAccount, i) => (
                  <div key={i} className={"grid grid-cols-1 gap-4 flex items-center justify-between py-2 border-b "}>
                    <div className={"bg-white px-4 pt-4 grid grid-cols-6 gap-4 " + (editFormData.id === i && " hidden")}>
                      <div className="col-start-1 col-end-5 ">
                        <p className="text-sm font-semibold">{subAccount.name}</p>
                        <p className="text-xs text-gray-500">{subAccount.phone_number}</p>
                      </div>
                      <div className="col-end-7 col-span-2">
                        <button
                          type="button"
                          className="border-2 border-_blue text-sm font-semibold text-_blue p-2 px-4 rounded-md hover:text-white hover:bg-_blue"
                          onClick={() => handleEditClick(i)}
                        >
                          Edit
                        </button>
                      </div>
                    </div>

                    {editFormData.id === i && (
                      <form className="bg-white px-1" onSubmit={handleEditFormSubmit}>
                        <label htmlFor="editName" className="text-sm text-gray-500">
                          Edit Name
                        </label>
                        <input
                          type="text"
                          id="editName"
                          name="name"
                          defaultValue={subAccount.name}
                          onChange={(e) => {
                            setEditFormData({ name: e.target.value, phone_number: editFormData.phone_number });
                          }}
                          className="block border border-gray-300 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-white rounded-lg appearance-none focus:outline-none focus:ring-0"
                        />

                        <label htmlFor="editphone_number" className="text-sm text-gray-500 mt-3">
                          Edit Phone Number
                        </label>
                        <input
                          type="tel"
                          id="editphone_number"
                          name="phone_number"
                          defaultValue={subAccount.phone_number}
                          onChange={(e) => {
                            setEditFormData({ phone_number: e.target.value, name: editFormData.name });
                          }}
                          className="block border border-gray-300 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-white rounded-lg appearance-none focus:outline-none focus:ring-0"
                        />
                        <div className='grid grid-cols-2 gap-4'>
                          <button
                            type="submit"
                            className="col-span-1 mt-3 w-32 text-center font-semibold text-sm py-1.5 rounded-md bg-green-600 text-white hover:bg-green-dark focus:outline-none"
                          >
                            Save
                          </button>
                          <button
                            type="submit"
                            className="col-span-1 mt-3 w-32 text-center font-semibold text-sm py-1.5 rounded-md bg-_red text-white hover:bg-green-dark focus:outline-none"
                            onClick={handleCancelEditForm}
                          >
                            Cancel
                          </button>
                        </div>
                      </form>
                    )}
                  </div>
                ))}

                {isAddFormOpen && (
                  <form className="mt-4" onSubmit={handleAddFormSubmit}>
                    <label htmlFor="addName" className="text-sm text-gray-500">
                      Add Name
                    </label>
                    <input
                      type="text"
                      id="addName"
                      name="name"
                      defaultValue={addFormData.name}
                      onChange={(e) => {
                        setAddFormData({ phone_number: e.target.value, name: editFormData.name });
                      }}
                      className="block border border-gray-300 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-white rounded-lg appearance-none focus:outline-none focus:ring-0"
                    />

                    <label htmlFor="addphone_number" className="text-sm text-gray-500 mt-3">
                      Add Phone Number
                    </label>
                    <input
                      type="tel"
                      id="addphone_number"
                      name="phone_number"
                      defaultValue={addFormData.phone_number}
                      onChange={(e) => {
                        setAddFormData({ phone_number: e.target.value, name: editFormData.name });
                      }}
                      className="block border border-gray-300 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-white rounded-lg appearance-none focus:outline-none focus:ring-0"
                    />
                    <div className='grid grid-cols-2 gap-4'>
                      <button
                        type="submit"
                        className="col-span-1 mt-3 w-32 text-center font-semibold text-sm py-1.5 rounded-md bg-green-600 text-white hover:bg-green-dark focus:outline-none"
                      >
                        Add
                      </button>
                      <button
                        type="button"
                        className="col-span-1 mt-3 w-32 text-center font-semibold text-sm py-1.5 rounded-md bg-_red text-white hover:bg-green-dark focus:outline-none"
                        onClick={handleCancelAddForm}
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                )}

                <div className="grid grid-cols-4 gap-4 px-1 flex justify-center py-1.5 ">
                  {(!isAddFormOpen && !isEditFormOpen) && (
                    <button
                      type="button"
                      onClick={() => {
                        setIsAddFormOpen(!isAddFormOpen);
                      }}
                      className="col-end-4 col-span-1 w-32 text-center font-semibold text-sm py-1.5 rounded-md bg-_blue text-white hover:bg-green-dark focus:outline-none my-1 "
                    >
                      Add User
                    </button>
                  )}
                </div>
              </div>
            )} */}

            <div className="flex text-red-500 text-sm">
              {error}
            </div>
            <div className="flex flex-col gap-2 mt-3">
              <button
                onClick={(e) => ChangePassword(e)}
                className='w-full block font-medium text-base bg-_blue text-white text-center p-2 rounded-md'>
                <span className={"animate-spin rounded-full h-4 w-4 border-t-4 border-_white-50 border-solid mr-4 " + (!loading && ' hidden')}></span>
                Change Password
              </button>
              <button
                onClick={(e) => Save(e)}
                className='w-full block font-medium text-base bg-_blue text-white text-center p-2 rounded-md'>
                Save Profile
              </button>
            </div>

            {/* <div className="flex flex-col w-full justify-center items-center">
              <div className=" w-full ">
                <button
                  type="button"
                  className=" "
                >
                 Change Password
                </button>
              </div>
            <div className=' py-1.5'>
              <button
                type="submit"
                className="  "
              >
                Update Account
              </button>
            </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserProfile;
