import React, { useEffect, useState } from "react";
import AdminSideBar from "../../component/admin/AdminSideBar";
import "../../styles/admin/mainPage.css"
import { Text } from "../../component/admin/Text";
import { Button, Input } from "antd";

import TableRenderer from "../../component/tableRenderer";
import { useNavigate } from "react-router-dom";
import { dataRetriever, updatePartObject } from "./BackendConnector";
import AdminStorage from "../../storage/AdminStorage";
const { Search } = Input;

// Searching
// const onSearch = (value, _e, info) => console.log(info?.source, value);


const AdminRestaurants = (props) => {
    const navigate = useNavigate()
    const [restaurantList, setRestaurantList] = useState(null);
    useEffect(() => {
        const getData = async () => {
            const response = await dataRetriever('restaurant')
            setRestaurantList(response)
        }
        getData();
        // eslint-disable-next-line
    }, [])
    useEffect(() => {
        if (!AdminStorage.Instance.auth)
            navigate('/admin')
    }, [])
    const restaurantSchema = {
        restaurant_img_path: "",
        restaurant_name: "",
        operational: true,
        restaurant_city: "",
        outlets: [],
        cuisines: [],
        menu: []

    }

    const detailsToDisplay = ["restaurant_img_path", "restaurant_name", "restaurant_city", "outlets", "operational"]
    const colName = ["LOGO", "Restaurant Name", "Restaurant City", "Outlets", "Operational"]

    const handleSwitch = async (checked, id, index) => {
        const response = await updatePartObject('restaurant/updateRestaurantSingle', id, checked, 'operational')
        if (response) {
            const copyRestaurant = [...restaurantList]
            copyRestaurant[index].operational = checked;
            setRestaurantList(copyRestaurant)
        }
    }
    const changeRoute = () => {
        navigate('/adminAddRestaurant', { state: { detail: restaurantSchema, type: "Add" } })
    }


    return (
        <div className="mainPage" >
            <AdminSideBar />
            <div className="mainContent">
                <Text className="w-auto" size="poppinsMediumHeading">
                    Restaurants
                </Text>
                <div className="topBar my-5">
                    <Search placeholder="Try Searching Restaurant Name, Location, Operational" allowclear onSearch enterButton className="w-search"
                    />

                    <Button type="primary" onClick={changeRoute} className='w-32 mx-2 bg-_blue hover:bg-blue-700 hover:text-_white-100 ' size='medium'>
                        Add
                    </Button>

                </div>
                <div className="my-5 relative overflow-x-auto shadow-md sm:rounded-lg">
                    <TableRenderer dataList={restaurantList} handleSwitch={handleSwitch} detailsToDisplay={detailsToDisplay} colName={colName} type="restaurant" btnText="View" viewPath="/adminRestaurantDetail" />
                </div>
            </div>
        </div>
    )
}
export default AdminRestaurants
