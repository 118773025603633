import axios from 'axios';
import { useEffect, useState } from 'react';

import localData from "../assets/client/data/temp.json"

const useRestaurantsMenu = (id) => {
  const [restaurantMenu, setRestaurantMenu] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const getRestaurantMenu = async () => {
    setIsLoading(true);
    setError(null);

    try {
      // const data = localData;
      const { data } = await axios.get(process.env.REACT_APP_URL + 'restaurant/getById', { params: { id: id } });

      if (Object.keys(data).length != 0)
        setRestaurantMenu(data);
      else
        setError("Data is unavailable");
    } catch (err) {
      console.log(err.response);
      setError(err.response);
    } finally {
      setIsLoading(false);
    }
  };


  useEffect(() => {
    getRestaurantMenu();
  }, []);

  return { restaurantMenu, isLoading, error };
};

export default useRestaurantsMenu;
