import React, { useState, useRef, useEffect } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Divider, Input, Select, Space, Button } from 'antd';
let index = 0;
const DropDown = (props) => {
    const [items, setItems] = useState(props.category);
    const [name, setName] = useState('');
    const inputRef = useRef(null);
    const onNameChange = (event) => {
        setName(event.target.value);
    };
    const [selectedItem, setSelectedItem] = useState(null);
    const onSelectChange = (value) => {
        props.setDetails(value, "category")
        setSelectedItem(value);
        props.form.setFieldsValue({ Category: value });
    };

    const addItem = (e) => {
        e.preventDefault();
        setItems([...items, name || `New item ${index++}`]);
        setName('');
        setTimeout(() => {
            inputRef.current?.focus();
        }, 0);
    };

    console.log(props.required)

    return (
        <Select
            style={{
                width: 300,
            }}
            onChange={(value) => onSelectChange(value)}
            defaultValue={props.defaultCategory}
            placeholder="custom dropdown render"
            disabled={props.disabled}
            rules={[{ required: props.required, message: 'Please input the Category!' }]}
            dropdownRender={(menu) => (
                <>
                    {menu}
                    <Divider
                        style={{
                            margin: '8px 0',
                        }}
                    />
                    <Space
                        style={{
                            padding: '0 8px 4px',
                        }}
                    >
                        <Input
                            placeholder="Please enter item"
                            ref={inputRef}
                            value={name}
                            onChange={onNameChange}
                            onKeyDown={(e) => e.stopPropagation()}
                        />
                        <Button type="text" icon={<PlusOutlined />} onClick={addItem}>
                            Add item
                        </Button>
                    </Space>
                </>
            )}
            options={items.map((item) => ({
                label: item,
                value: item,
            }))}
        />
    );
};
export default DropDown;