import React, { useEffect, useState } from "react";
import { Text } from "../../component/admin/Text";
import AdminSideBar from "../../component/admin/AdminSideBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faLocationDot, faTrash } from "@fortawesome/free-solid-svg-icons";

import "../../styles/admin/mainPage.css"
import { Link, useNavigate } from "react-router-dom";
import veg from "../../assets/admin/svg/veg-icon.svg"
import nonVeg from "../../assets/admin/svg/nonveg-icon.svg"
import jain from "../../assets/admin/svg/jain-icon.svg"
import edit from "../../assets/admin/svg/edit-icon.svg"

import { Avatar, Button, Modal, Empty, Switch } from "antd";

import AdminAddItem from "./AdminAddItem";
import { useLocation } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

import restaurantSvg from "../../assets/restaurant.svg"
import itemSvg from "../../assets/item.svg"

import { dataRetrieverById, dataSenderById, deleteItemData, updateItem, deleteCategory } from "./BackendConnector";
import AdminStorage from "../../storage/AdminStorage";



const AdminRestaurantDetail = () => {
    const location = useLocation();
    const navigate = useNavigate()

    useEffect(() => {
        if (!AdminStorage.Instance.auth)
            navigate('/admin')
    }, [])


    const [restaurantDetail, setRestaurantDetail] = useState(null);
    const [existingCat, setCategoryIndex] = useState()
    const [switchLoading, setSwitchLoading] = useState(false)
    const [menuFormat, setMenuFormat] = useState({
        category: "",
        items:
        {
            item_id: '',
            item_img_path: "",
            item_type: "veg",
            item_name: "",
            item_price: 0,
            description: "",
            add_ons: [],
            available: true

        }
    })
    const categoryList = {}
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editedMenuFormat, setEditedMenuFormat] = useState(null);
    const [updated, setIsUpdated] = useState(false)
    const [type, setType] = useState('add')
    const [index, setIndex] = useState()

    useEffect(() => {
        const getDataFromDB = async () => {
            const response = await dataRetrieverById('restaurant', location.state.id)
            setRestaurantDetail(response);
        }
        getDataFromDB()
        // eslint-disable-next-line
    }, [])

    const showModal = (params) => {
        const updateStateAndOpenModal = () => {
            if (params.type === 'edit') {
                setType('edit')
                setIndex(params.itemIndex)
                setEditedMenuFormat({
                    category: params.item.category,
                    items: {
                        item_id: params.item.items.item_id,
                        item_img_path: params.item.items.item_img_path,
                        item_type: params.item.items.item_type,
                        item_name: params.item.items.item_name,
                        item_price: params.item.items.item_price,
                        description: params.item.items.description,
                        add_ons: params.item.items.add_ons,
                        available: params.item.items.available,
                    }
                });

            }
            else {
                setType('add')
            }
            setIsModalOpen(true);
        };
        updateStateAndOpenModal()
    };


    const handleCancel = () => {
        setIsModalOpen(false);
        window.location.reload()
    };
    const handleSwitch = async (checked, item, category, catIndex, itemIndex) => {
        setSwitchLoading(true)
        const data = {
            ...item,
            available: checked,
        };

        const response = await updateItem("restaurant", restaurantDetail.restaurant_id, data, category)
        if (response) {
            setSwitchLoading(false)
            const temp = { ...restaurantDetail };
            temp.menu[catIndex].items[itemIndex] = data;
            setRestaurantDetail(temp);
        }
    }


    const deleteCategoryHandler = async (id, category) => {

        const response = await deleteCategory('restaurant', id, category);
        if (response) {
            const updatedMenu = restaurantDetail.menu.filter((cat) => cat.category !== category)
            const restaurantCopy = { ...restaurantDetail }
            restaurantCopy.menu = updatedMenu
            setRestaurantDetail(restaurantCopy)
            // setRestaurantDetail(updatedMenu)

        }

    }

    const detailsRenderer = () => {
        if (restaurantDetail !== null) {

            if (restaurantDetail.menu.length >= 1 && restaurantDetail.menu[0].category !== "") {
                return restaurantDetail.menu.map((menuItem, catIndex) => {
                    categoryList[menuItem.category] = catIndex
                    return (
                        <div className="mt-5" key={restaurantDetail.restaurant_id}>
                            <div className="mb-5">
                                <Text size={'poppinsRegularSubHeading'} className="p-2">{menuItem.category}</Text>
                                <FontAwesomeIcon icon={faTrash} size="sm" className="text-_blue cursor-pointer" onClick={() => { deleteCategoryHandler(restaurantDetail.restaurant_id, menuItem.category) }} />
                            </div>
                            {
                                menuItem.items.map((item, itemIndex) => {

                                    return (
                                        <div key={uuid()} className="flex justify-between my-5">
                                            <div className="w-fit">
                                                <CustomAvatar
                                                    img={item.item_img_path}
                                                    defaultImg={itemSvg}
                                                    shape="square"
                                                    size={{
                                                        md: 40,
                                                        lg: 60,
                                                        xl: 80,
                                                        xxl: 100,
                                                    }}

                                                />

                                            </div>
                                            <div className="w-8/12  flex flex-col justify-start">
                                                <div className="flex">
                                                    <div>
                                                        <Avatar shape="square" size=
                                                            {{
                                                                xs: 4,
                                                                sm: 8,
                                                                md: 10,
                                                                lg: 16,
                                                                xl: 16,
                                                                xxl: 24,
                                                            }}
                                                            src={item.item_type === 'veg' ? veg : item.item_type === 'jain' ? jain : nonVeg} />
                                                    </div>

                                                    <Text className="ml-1 flex items-center" size={"poppinsRegularSubHeading"} comp="p">
                                                        {item.item_name}
                                                    </Text>

                                                </div>

                                                <Text className="break-word mt-1" size={"poppinsRegularDecription"} comp="p">
                                                    {item.description}
                                                </Text>
                                                <Text className="mt-1 " size={"poppinsRegularDecription"} comp="p">
                                                    ₹{item.item_price}
                                                </Text>
                                            </div>
                                            <div className="w-1/6 flex items-center">
                                                <Switch className="switch" checked={item.available} loading={switchLoading} onChange={(checked) => handleSwitch(checked, item, menuItem.category, catIndex, itemIndex)} />

                                                <Button type="primary" className="border-_blue text-_blue mx-2" onClick={() => showModal({ item: { category: menuItem.category, items: item }, itemIndex: itemIndex, type: "edit" })}>
                                                    Edit
                                                </Button>

                                                <FontAwesomeIcon icon={faTrash} className="text-_blue cursor-pointer" onClick={() => { deleteItem(catIndex, itemIndex) }} />

                                            </div>
                                        </div>)
                                })
                            }
                        </div>
                    )
                })
            }
            else {
                return <Empty />
            }
        }

        else {
            return <Empty />
        }
    }

    useEffect(() => {
        const dataSender = async () => {
            if (type === 'edit') {
                if (updated) {
                    const data = restaurantDetail.menu[existingCat].items[index]
                    const category = restaurantDetail.menu[existingCat].category

                    const response = await updateItem('restaurant', restaurantDetail.restaurant_id, data, category)

                    if (response) {
                        setIsUpdated(false)
                        // window.location.reload()
                    }
                }

            }
            if (type === 'add') {

                if (updated) {
                    const data = existingCat !== null ? restaurantDetail.menu[existingCat].items[restaurantDetail.menu[existingCat].items.length - 1] : restaurantDetail.menu[restaurantDetail.menu.length - 1]
                    const category = existingCat !== null ? restaurantDetail.menu[existingCat].category : restaurantDetail.menu[restaurantDetail.menu.length - 1].category
                    const response = await dataSenderById('restaurant', restaurantDetail.restaurant_id, category, data)

                    if (response) {
                        setIsUpdated(false)
                        //  window.location.reload()
                    }
                }
            }
        }
        dataSender()
        //eslint-disable-next-line
    }, [updated])
    const deleteItem = async (catIndex, itemIndex) => {
        const response = await deleteItemData('restaurant', restaurantDetail.restaurant_id, catIndex, itemIndex);
        if (response) {
            const response = await dataRetrieverById("restaurant", restaurantDetail.restaurant_id);
            setRestaurantDetail(response)
        }
    }


    const CustomAvatar = ({ img, defaultImg, ...otherProps }) => {
        const [avatarSrc, setAvatarSrc] = useState(img);

        const handleError = () => {
            setAvatarSrc(defaultImg);
        };

        return <Avatar {...otherProps} src={avatarSrc} onError={handleError} />;
    };


    const handleRoute = () => {
        navigate("/adminEditRestaurant", { state: { detail: restaurantDetail, type: "Edit" } })
    }

    return (
        <div className="mainPage">
            <AdminSideBar />
            {restaurantDetail &&
                <div key={uuid()} className="mainContent">
                    <div className="flex-start">
                        <Link to={'/adminRestaurant'}>
                            <FontAwesomeIcon className="mx-3" icon={faArrowLeft} />
                        </Link>
                        <Text className="w-auto mb-5" size="poppinsMediumHeading">
                            Restaurants
                        </Text>
                    </div>
                    <div className="topBar bg-_white-100 mx-7 mt-7 rounded-xl px-5 py-2">
                        <div className="flex-col justify-start">
                            <div>

                                <CustomAvatar
                                    img={restaurantDetail.restaurant_img_path}
                                    defaultImg={restaurantSvg}
                                    shape="square"
                                    size={{
                                        md: 32,
                                        lg: 40,
                                        xl: 60,
                                        xxl: 100,
                                    }}
                                    className="mr-2"
                                />

                                <Text className="ml-2.5" size={"poppinsRegularHeading"}>
                                    {restaurantDetail !== null ? restaurantDetail.restaurant_name : "Loading"}
                                </Text>

                                <Avatar className="text-_blue cursor-pointer ml-2.5" shape="square" size=
                                    {{
                                        xs: 4,
                                        sm: 8,
                                        md: 10,
                                        lg: 16,
                                        xl: 20,
                                        xxl: 24,
                                    }}
                                    src={edit} onClick={handleRoute} />

                            </div>
                            <div className="flex">
                                <div className="px-2">
                                    <FontAwesomeIcon icon={faLocationDot} size="xs" />
                                </div>
                                <div>
                                    <Text size="poppinsRegularDecription">
                                        {restaurantDetail !== null ? restaurantDetail.restaurant_city : null}
                                    </Text>
                                </div>
                            </div>
                        </div>
                        <div className="flex items-center">
                            <Button type="primary" className="bg-_green text-_white-50 mx-3">
                                Import
                            </Button>
                            <Button type="primary" className="bg-_blue text-_white-50 mx-2" onClick={() => showModal({ item: null, type: "add" })}>
                                Add
                            </Button>
                        </div>
                    </div>
                    <div className="mx-8 relative overflow-x-auto ">
                        {detailsRenderer()}
                    </div>

                    <Modal title={`${type === "edit" ? 'Edit' : "Add"} Item`} width={600} open={isModalOpen} onCancel={handleCancel} footer={false}>
                        <AdminAddItem setIsModalOpen={setIsModalOpen} menuItem={type === 'edit' ? editedMenuFormat : menuFormat} itemIndex={index} type={type} setMenuItem={type === 'edit' ? setEditedMenuFormat : setMenuFormat} catList={categoryList} setCatIndex={setCategoryIndex} restDetail={restaurantDetail} setRestDetail={setRestaurantDetail} setIsUpdate={setIsUpdated} />
                    </Modal>

                </div>
            }
        </div>
    )
}
export default AdminRestaurantDetail