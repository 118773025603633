import { Link } from 'react-router-dom';

import RestaurantCard from './RestaurantCard';
import ShimmerCard from '../shimmer/ShimmerCard';
import DisplayError from '../DisplayError';

const RestaurantList = ({ isLoading, restaurants, error }) => {

  return (
    <div>
      {error ? (
        <DisplayError title={"Error"} description={error} />
      ) : (
        <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 md:gap-8'>
          {isLoading ? (
            Array.from({ length: 15 }).map((_, i) => <ShimmerCard key={i} />)
          ) : (
            restaurants?.map((restaurant, i) => (
              restaurant.operational &&
              <Link
                to={`/restaurants/${restaurant.restaurant_id}`}
                className='hover:scale-95 transition ease-in-out duration-300 relative z-10'
                key={i}
              >
                <RestaurantCard  restaurant={restaurant} />
              </Link>
            ))
          )
          }
        </div>
      )
      }
    </div>
  );
};

export default RestaurantList;
