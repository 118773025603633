import axios from 'axios';
import { useEffect, useState } from 'react';

import localData from "../assets/client/data/temp.json"

const useRestaurants = (url) => {
  const [restaurants, setRestaurants] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const getRestaurants = async () => {
    setIsLoading(true);
    setError(null);

    try {
      // const data = localData;
      const { data } = await axios.get(process.env.REACT_APP_URL + 'restaurant/get');

      if (Object.keys(data).length != 0)
        setRestaurants(data);
      else
        setError("Data is unavailable");
    } catch (err) {
      console.log(err.response);
      setError(err.response);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getRestaurants();
  }, []);

  return {
    restaurants,
    isLoading,
    error,
  };
};

export default useRestaurants;
