// import React from "react";
import Compressor from 'compressorjs';
import axios from "axios";

const upiScreenShotUploader = (props) => {

    const uploadImage = async ({ file, onSuccess }) => {
        new Compressor(file, {
            quality: 0.6,
            maxHeight: 500,
            maxWidth: 500,
            success: async (compressedResult) => {
                try {
                    const formData = new FormData();
                    formData.append('image', compressedResult);
                    const response = await axios.post(process.env.REACT_APP_URL + "image" + "/uploadUPISS", formData, { params: { user_name: props.user_name.name } });

                    // // Update the state here with the uploaded image data
                    props?.setRefID(response.data);
                    props?.setIsUploaded(true);
                    props?.setFileList([{
                        uid: '-1',
                        name: props.user_name.name + "UPISS",
                        status: 'done',
                        url: response.data,
                    }]
                    )
                    return new Promise((resolve) => {
                        setTimeout(() => {
                            onSuccess(response);
                            resolve();
                        }, 0);
                    });
                } catch (error) {
                    console.log('Image upload failed:', error);
                }
            }
        })

    };
    const removeImage = async ({ url }) => {

        try {
            if (url !== "") {

                const response = await axios.delete(process.env.REACT_APP_URL + "image/deleteUPISS", { params: { url: url } });
                if (response.status === 200) {
                    if (props?.setIsUploaded !== undefined) {
                        props?.setIsUploaded(false)
                        props?.setRefID('');
                        props?.setFileList([])
                    }
                    if (props?.setOrderDetails !== undefined) {

                        props?.setOrderDetails((prev) => ({
                            ...prev, 'invoice_id': 'Reference ID Checked'
                        })) // Clear the image path in your state
                    }
                } else {
                    console.log('Failed to delete image:', response);
                }
            }
            else {
                props?.setIsUploaded(false)
            }
        } catch (error) {
            console.log('Error deleting image:', error);
        }
    }


    return { uploadImage, removeImage }
}
export default upiScreenShotUploader

