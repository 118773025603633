import { useState } from "react";
import { faChevronDown, faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Dropdown = ({list, initialValue, callbackFx}) => {

  const [index, setIndex] = useState(initialValue);
  const [isOpen, setIsOpen] = useState(false);

  const ValueChange=(i)=>{
    setIndex(i);
    setIsOpen(false);
    callbackFx(i);
  }

  return (
    <div onMouseLeave={()=>setIsOpen(false)} className="relative group">
      <div onClick={()=>{setIsOpen(!isOpen)}}
        className="flex cursor-pointer justify-between text-base border-2 border-_blue font-semibold text-_blue p-2 px-4 items-center gap-x-4 gap-y-2 rounded-md">
        <div>{list[index]}
        </div><div><FontAwesomeIcon icon={faChevronDown} /></div>
      </div>
      
      {isOpen ?
        <div className="absolute bg-gray-100 z-10 border border-gray-300 rounded-md right-0 ">
          { list.map((value, i) => (
            <div onClick={()=>ValueChange(i)} 
              className={'block flex justify-between items-center cursor-pointer gap-10 px-4 w-full justify-center py-2 hover:bg-blue-200 ' + (i === index ? "text-_blue":"text-gray-800 ")} 
              key={i}>
                <div className="max-w-full whitespace-nowrap">{value}</div>
                {i === index && <FontAwesomeIcon icon={faCheck} />}
            </div>
          ))}
        </div>:null
      }
    </div>
  );
};

export default Dropdown;