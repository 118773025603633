const ShimmerOrderDetails = () => {
    return (
  <div className='animate-pulse'>
    <div className="gap-5 md:flex md:flex-row-reverse">
    <div className='basis-5/12 aspect-square w-full space-y-4 px-10 bg-gray-200 rounded-md mb-7'></div>

      <div className="basis-7/12">
      <div className='space-y-4 mb-8'>
        <div className='w-full bg-gray-200 h-12 rounded-md'></div>
        <div className='flex w-full justify-between gap-4 px-6'>
          <div className='h-24 w-full basis-2/12 aspect-square  bg-gray-200 rounded-md'></div>
          <div className='space-y-2 grow py-4'>
            <div className='h-4 bg-gray-200 w-[80%] rounded-md'></div>
            <div className='h-2 bg-gray-200 w-[50%] rounded-md'></div>
            <div className='h-2 bg-gray-200 w-[20%] rounded-md'></div>
          </div>
        </div>
        <div className='flex w-full justify-between gap-4 px-6'>
          <div className='h-24 w-full basis-2/12 aspect-square  bg-gray-200 rounded-md'></div>
          <div className='space-y-2 grow py-4'>
            <div className='h-4 bg-gray-200 w-[80%] rounded-md'></div>
            <div className='h-2 bg-gray-200 w-[50%] rounded-md'></div>
            <div className='h-2 bg-gray-200 w-[20%] rounded-md'></div>
          </div>
        </div>
        <div className='flex w-full justify-between gap-4 px-6'>
          <div className='h-24 w-full basis-2/12 aspect-square  bg-gray-200 rounded-md'></div>
          <div className='space-y-2 grow py-4'>
            <div className='h-4 bg-gray-200 w-[80%] rounded-md'></div>
            <div className='h-2 bg-gray-200 w-[50%] rounded-md'></div>
            <div className='h-2 bg-gray-200 w-[20%] rounded-md'></div>
          </div>
        </div>
      </div>

      <div className='space-y-4'>
        <div className='w-full bg-gray-200 h-12 rounded-md'></div>
        <div className='flex w-full justify-between gap-4 px-6'>
          <div className='h-24 w-full basis-2/12 aspect-square  bg-gray-200 rounded-md'></div>
          <div className='space-y-2 grow py-4'>
            <div className='h-4 bg-gray-200 w-[80%] rounded-md'></div>
            <div className='h-2 bg-gray-200 w-[50%] rounded-md'></div>
            <div className='h-2 bg-gray-200 w-[20%] rounded-md'></div>
          </div>
        </div>
        <div className='flex w-full justify-between gap-4 px-6'>
          <div className='h-24 w-full basis-2/12 aspect-square  bg-gray-200 rounded-md'></div>
          <div className='space-y-2 grow py-4'>
            <div className='h-4 bg-gray-200 w-[80%] rounded-md'></div>
            <div className='h-2 bg-gray-200 w-[50%] rounded-md'></div>
            <div className='h-2 bg-gray-200 w-[20%] rounded-md'></div>
          </div>
        </div>
        <div className='flex w-full justify-between gap-4 px-6'>
          <div className='h-24 w-full basis-2/12 aspect-square  bg-gray-200 rounded-md'></div>
          <div className='space-y-2 grow py-4'>
            <div className='h-4 bg-gray-200 w-[80%] rounded-md'></div>
            <div className='h-2 bg-gray-200 w-[50%] rounded-md'></div>
            <div className='h-2 bg-gray-200 w-[20%] rounded-md'></div>
          </div>
        </div>
      </div>
      </div>
      
    </div>
  </div>
    );
  };
  
  export default ShimmerOrderDetails;
  