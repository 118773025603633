// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getAuth,GoogleAuthProvider,} from "firebase/auth"



const firebaseConfig = {
  apiKey: "AIzaSyDJPRGrzO8AqELCtFOtiV0FkctrdkVr5mc",
  authDomain: "sneko-d26c1.firebaseapp.com",
  projectId: "sneko-d26c1",
  storageBucket: "sneko-d26c1.appspot.com",
  messagingSenderId: "91999050133",
  appId: "1:91999050133:web:7fcbce4d2ead7d71c83e9c"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app)
const provider = new GoogleAuthProvider();
export {auth,provider};
