const ShimmerOrderHistory = () => {
    return (
  <div className='animate-pulse'>

        <div className='w-full my-4 bg-gray-200 h-16 rounded-md'></div>
        <div className='space-y-4 px-10'>
          <div className='flex w-full justify-between gap-4'>
            <div className='h-24 w-full basis-2/12 aspect-square md:aspect-video bg-gray-200 rounded-md'></div>
            <div className='space-y-2 grow py-4'>
              <div className='h-4 bg-gray-200 w-[80%] rounded-md'></div>
              <div className='h-2 bg-gray-200 w-[50%] rounded-md'></div>
              <div className='h-2 bg-gray-200 w-[20%] rounded-md'></div>
            </div>
          </div>
          <div className='flex w-full justify-between gap-4'>
            <div className='h-24 w-full basis-2/12 aspect-square md:aspect-video bg-gray-200 rounded-md'></div>
            <div className='space-y-2 grow py-4'>
              <div className='h-4 bg-gray-200 w-[80%] rounded-md'></div>
              <div className='h-2 bg-gray-200 w-[50%] rounded-md'></div>
              <div className='h-2 bg-gray-200 w-[20%] rounded-md'></div>
            </div>
          </div>
          <div className='flex w-full justify-between gap-4'>
            <div className='h-24 w-full basis-2/12 aspect-square md:aspect-video bg-gray-200 rounded-md'></div>
            <div className='space-y-2 grow py-4'>
              <div className='h-4 bg-gray-200 w-[80%] rounded-md'></div>
              <div className='h-2 bg-gray-200 w-[50%] rounded-md'></div>
              <div className='h-2 bg-gray-200 w-[20%] rounded-md'></div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  export default ShimmerOrderHistory;
  