import axios from "axios";
import UserStorage from "./UserStorage";

class CartStorage {
    // TO DO
    // const { setCart } = useCart(url);
    // IF login call setCart with url

    cartItems = [];
    itemCount = 0;
    totalAmount = 0;

    listener = [];

    static get Instance() {
        if (!CartStorage._instance) {
            CartStorage._instance = new CartStorage();
        }
        return CartStorage._instance;
    }

    AddListener(component) {
        this.listener.push(component);
    }
    RemoveListener(component) {
        this.listener.filter((item) => item !== component);
    }

    async LoadDatabaseData() {

        const { data } = await axios.get(process.env.REACT_APP_URL + 'cart/getCartByUserId', { params: { user_id: UserStorage.Instance.id } });

        this.cartItems = data.cart;
        this.UpdateCartTotal();

        const totalQty = this.cartItems.map((restaurant) => {
            return restaurant.items.length;
        });
        const updatedQty = totalQty.reduce((sum, value) => sum + value, 0);
        this.itemCount = updatedQty;

        this.SetCacheData();
    }

    LoadCacheData() {
        const cartCache = localStorage.getItem('cart');
        if (cartCache) {
            const cartJson = JSON.parse(cartCache);
            this.itemCount = cartJson.itemCount;
            this.totalAmount = cartJson.cartTotal;
            this.cartItems = cartJson.cart;

            this.listener.forEach((callback) => {
                callback(prevState => prevState + 1);
            });
        }
    }

    SetCacheData() {
        if (UserStorage.Instance.auth) {
            this.SetDatabaseData();
        }

        localStorage.setItem('cart', JSON.stringify({
            "itemCount": this.itemCount,
            "cartTotal": this.totalAmount,
            "cart": this.cartItems
        }));
        this.listener.forEach((callback) => {
            callback(prevState => prevState + 1);
        });
    }

    async SetDatabaseData() {
        const data = {
            user_id: UserStorage.Instance.id,
            cart: this.cartItems
        }

        await axios.post(process.env.REACT_APP_URL + 'cart/updateCartByUserId', { user_id: UserStorage.Instance.id, data: data });
    }

    GetQuantity(restaurant_id, item_id) {
        const restaurantIndex = this.cartItems.findIndex((r) => r.restaurant_id === restaurant_id);
        if (restaurantIndex === -1)
            return 0;

        const itemIndex = this.cartItems[restaurantIndex].items.findIndex((i) => i.item_id === item_id);
        if (itemIndex === -1)
            return 0;

        return this.cartItems[restaurantIndex].items[itemIndex].qty;
    }

    GetAddOn(restaurant_id, item_id) {
        const restaurantIndex = this.cartItems.findIndex((r) => r.restaurant_id === restaurant_id);
        if (restaurantIndex === -1)
            return [];

        const itemIndex = this.cartItems[restaurantIndex].items.findIndex((i) => i.item_id === item_id);
        if (itemIndex === -1)
            return [];

        return this.cartItems[restaurantIndex].items[itemIndex].add_ons;
    }


    DeleteCache() {
        localStorage.clear();
        this.cartItems = [];
        this.itemCount = 0;
        this.totalAmount = 0;
        this.SetCacheData();
    }

    AddOnChange(restaurant_id, item_id, add_ons) {
        this.cartItems = this.cartItems.map((restaurant) => {
            if (restaurant.restaurant_id === restaurant_id) {
                const itemIndex = restaurant.items.findIndex((i) => i.item_id === item_id);
                restaurant.items[itemIndex].add_ons = add_ons;
            }
            return restaurant;
        });
        
        this.UpdateCartTotal();
        this.SetCacheData();
    }

    UpdateCartTotal() {
        const totalList = this.cartItems.map((restaurant) => {
            return restaurant.items.reduce((sum, item) => {
                const extra = item.add_ons.reduce((sum, add) => sum + add.price,0)
                return sum + item.qty * (item.item_price + extra)
            }, 0);
        });
        const updatedTotal = totalList.reduce((sum, value) => sum + value, 0);
        this.totalAmount = updatedTotal;
    }

    AddToCart = (item) => {
        const restaurantIndex = this.cartItems.findIndex((r) => r.restaurant_id === item.restaurant_id);
        if (restaurantIndex === -1) {
            this.cartItems.push({
                restaurant_name: item.restaurant_name,
                restaurant_img_path: item.restaurant_img_path,
                restaurant_id: item.restaurant_id,
                items: [item.item],
            });
        } else {
            const updatedItems = [...this.cartItems[restaurantIndex].items, item.item];
            this.cartItems[restaurantIndex] = { ...this.cartItems[restaurantIndex], items: updatedItems };
        }

        this.itemCount++;
        this.UpdateCartTotal();
        this.SetCacheData();
    };

    RemoveFromCart = (restaurant_id, item_id) => {
        let removeRestaurant = false;
        this.cartItems = this.cartItems.map((restaurant) => {
            if (restaurant.restaurant_id === restaurant_id) {
                const updatedItems = restaurant.items.filter((item) => item.item_id !== item_id);
                if (updatedItems.length === 0) {
                    removeRestaurant = true;
                    return -1;
                }
                return { ...restaurant, items: updatedItems };
            }
            return restaurant;
        });

        if (removeRestaurant)
            this.cartItems = this.cartItems.filter((item) => item !== -1)

        this.itemCount--;
        this.UpdateCartTotal();
        this.SetCacheData();
    };

    ChangeQuantity = (restaurant_id, item_id, qty) => {
        this.cartItems = this.cartItems.map((restaurant) => {
            if (restaurant.restaurant_id === restaurant_id) {
                const itemIndex = restaurant.items.findIndex((i) => i.item_id === item_id);
                restaurant.items[itemIndex].qty = qty;
            }
            return restaurant;
        });

        this.UpdateCartTotal();
        this.SetCacheData();
    };
}

export default CartStorage;
