import React from 'react';
import dayjs from 'dayjs';
import { DatePicker, Space } from 'antd';
const { RangePicker } = DatePicker;

const DateSelect = (props) => (
    <DatePicker
        presets={[
            {
                label: 'Yesterday',
                value: dayjs().add(-1, 'd'),
            },
            {
                label: 'Tomorrow',
                value: dayjs().add(+1, 'd'),
            },
        ]}
        className={props.className}
        format={props.format}
        onChange={props.dateRanger}
    />

);
export default DateSelect;