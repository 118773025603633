import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
//client
import NotFound from "../pages/NotFound";
import UserProfile from "../component/client/login/UserProfile";



//admin
import AdminHome from "../pages/admin/AdminHome";
import AdminAddRestaurant from "../pages/admin/AdminAddRestaurant";
import AdminRestaurants from "../pages/admin/AdminRestaurants";
import AdminCustomer from "../pages/admin/AdminCustomer";
import AdminRestaurantDetail from "../pages/admin/adminRestaurantDetail";
import HomePage from "../pages/client/HomePage";
import RestaurantPage from "../pages/client/RestaurantPage";
import CartPage from "../pages/client/CartPage";
import OrderHistoryPage from "../pages/client/OrderHistoryPage";
import OrderHistoryDetailsPage from "../pages/client/OrderHistoryDetailsPage";
import Login from "../component/client/login/Login";
import LoginPwd from "../component/client/login/LoginPwd";
import SignIn from "../component/client/login/SignIn";
import ChangePassword from "../component/client/login/ChangePassword";
import AdminLogin from "../pages/admin/AdminLogin";
import OrderPlaced from "../pages/client/OrderPlaced";
import OtpAuth from "../component/client/login/OtpAuth";


const ProjectRoutes = () => {
  return (
    <React.Suspense fallback={<>Loading...</>}>
      <Router>
        <Routes>
          {/* Client Routes */}
          <Route path="/" element={<HomePage />} />
          <Route path="/restaurants/:id" element={<RestaurantPage />} />
          <Route path="/cart" element={<CartPage />} />
          <Route path="/orderHistory/:id" element={<OrderHistoryPage />} />
          <Route path="/orderHistory/:id/:orderID" element={<OrderHistoryDetailsPage />} />
          <Route path="/profile" element={<UserProfile />} />
          <Route path="/login" element={<Login />} />
          <Route path="/pwd" element={<LoginPwd />} />
          <Route path="/resetPwd" element={<ChangePassword />} />
          <Route path="/otp" element={<OtpAuth />} />
          <Route path="/signup" element={<SignIn />} />
          <Route path="/orderPlaced" element={<OrderPlaced />} />
          <Route path="*" element={<NotFound />} />

          {/* Admin Routes */}
          <Route path="/admin" element={<AdminLogin />} />
          <Route path="/adminOrder" element={<AdminHome pageName="Orders" />} />
          <Route path="/adminRestaurant" element={<AdminRestaurants pageName="Restaurants" />} />
          <Route path="/adminCustomer" element={<AdminCustomer pageName="Customers" />} />
          <Route path="/adminAddRestaurant" element={<AdminAddRestaurant pageName="Customers" />} />
          <Route path="/adminEditRestaurant" element={<AdminAddRestaurant pageName="Customers" />} />
          <Route path="/adminRestaurantDetail" element={<AdminRestaurantDetail pageName="Customers" />} />

        </Routes>
      </Router>
    </React.Suspense>
  );
};
export default ProjectRoutes;
