import React, { useEffect, useState } from 'react'
import SnekoLogo from '../../../assets/client/image/snekoLogoTrans.png'
import { auth } from '../../../helper/firebase-config';
import { createUserWithEmailAndPassword } from "firebase/auth";
import { useLocation, useNavigate } from 'react-router-dom';
import Navbar from '../Navbar';
import UserStorage from '../../../storage/UserStorage';
import axios from 'axios';

function SignIn() {
  const location = useLocation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false)
  const [confirmPassword, setConfirmPassword] = useState("");
  const [registerPassword, setRegisterPassword] = useState('');
  const [fullName, setFullName] = useState(location.stat?.name);
  const [phoneNumber, setPhoneNumber] = useState(location.state?.ph);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (!location.state) {
      navigate("/");
    }
    setFullName(location.state?.name);
    setPhoneNumber(location.state?.ph);
  }, [])

const register = async (e) => {
    e.preventDefault()

    setErrorMessage('');
    setLoading(true);
    if (!fullName || !phoneNumber) {
      setErrorMessage('All fields are mandatory.');
      setLoading(false);
      return;
    }
    if (phoneNumber.length != 10) {
      setErrorMessage('Phone Number Should be equal to 10 digits.');
      setLoading(false);
      return;
    }
    if (Number.isNaN(phoneNumber)) {
      setErrorMessage('Phone Number should only contain digits');
      setLoading(false);
      return;
    }
    if (location.state.emailSignIn) {
      if (registerPassword.length < 6) {
        setErrorMessage('Password should be minimum of 6 characters');
      setLoading(false);
      return;
      }
      if (!registerPassword || !confirmPassword) {
        setErrorMessage('All fields are mandatory.');
      setLoading(false);
      return;
      }
      if (registerPassword !== confirmPassword) {
        setErrorMessage('Password and Confirm Password do not match.');
      setLoading(false);
      return;
      }
    }

    try{
      const unique = await axios.get(process.env.REACT_APP_URL + 'userDetails/uniqueNumber', {params : { phone_number : phoneNumber }});
      if(unique.data){
        setErrorMessage('Phone Number Already Used.');
        setLoading(false);
        return;
      }

    }catch (error){
      console.log(error.message);
      setErrorMessage('Error while Creating Account');
      setLoading(false);
      return;
    }



    try {
      let userData = {}
      if (location.state.emailSignIn) {
        if (location.state.user_auth) {
          await axios.post(process.env.REACT_APP_URL + 'userDetails/setUserDetailAuth', { user_id: location.state.id, user_auth: 3 });
        } else {

          const user = await createUserWithEmailAndPassword(auth, location.state.email, confirmPassword)
          userData = {...userData, user_auth: 2}
        }
      } else {
        userData = {...userData, user_auth: 1}
      }
      userData = { ...userData,
        user_phone_number: phoneNumber,
        user_photo_path: "",
        user_name: fullName,
        user_email: location.state.email,
        user_age: "",
        user_gender: "",
        Addresses: []
      }
      await axios.post(process.env.REACT_APP_URL + 'userDetails/add', userData);
      
      const details = await axios.get(process.env.REACT_APP_URL + 'userDetails/getByEmail', { params: { email: location.state?.email } });
      if (!details) {
        setErrorMessage('Error while Creating Account. Try again Later');
        return;
      }
      UserStorage.Instance.Login({
        id: details.data.user_id,
        email: details.data.user_email,
        name: details.data.user_name,
        phone_number: details.data.user_phone_number,
        subUser: details.data.delivery_for
      })
      navigate('/');
    }
    catch (error) {
      console.log(error.message);
      setErrorMessage('Error while Creating Account');
    }
    setLoading(false);
  }

  return (
    <div >
      <div className="bg-white flex flex-colbg-white w-5/6 md:w-3/4 lg:w-2/3 xl:w-[400px] 2xl:w-[450px] mt-20 mx-auto px-15 py-5 rounded-lg ">

        <div className="container max-w-screen-sm mx-auto flex-1 flex flex-col items-center justify-center px-2">
          <div >
            <img className=" sm:mx-auto sm:w-32 sm:max-w-sm w- md:w-60 lg:w-48" src={SnekoLogo} alt="Your Company"></img>
          </div>
          <form className="bg-transparent px-10 py-1 rounded-xl  text-black w-full">
            <h1 className="text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">Sign Up</h1>
            
            <div className="mt-3 relative ">
              <input type="text" id="full_name" defaultValue={location.state?.name} onChange={(event) => { setFullName(event.target.value) }} className="block border border-gray-300 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-white rounded-lg  appearance-none focus:outline focus:ring-0  peer" placeholder="" />
              <label htmlFor="full_name" className="absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 start-1 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">Full Name</label>
            </div>

            <div className="mt-3 relative ">
              <input type="email" id="email" disabled={true} value={location.state?.email} className="block border border-gray-300 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-gray-200 rounded-lg  appearance-none focus:outline focus:ring-0  peer" placeholder="" />
              <label htmlFor="email" className="absolute bg-gradient-to-t from-gray-200 to-white text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 start-1 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">Email</label>
            </div>

            <div className="mt-3 relative ">
              <input type="tel" id="phone_number" defaultValue={location.state?.ph} onChange={(event) => { setPhoneNumber(event.target.value) }} className=" block border border-gray-300 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-white rounded-lg  appearance-none focus:outline focus:ring-0  peer" placeholder="" />
              <label htmlFor="phone_number" className="absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 start-1 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">Phone Number</label>
            </div>

            {location.state.emailSignIn &&
              <>
                <div className="mt-3 relative ">
                  <input type="password" id="password" onChange={(event) => { setRegisterPassword(event.target.value) }} className="block border border-gray-300 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-white rounded-lg  appearance-none focus:outline focus:ring-0  peer" placeholder="" />
                  <label html="password" className="absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 start-1 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">Password</label>
                </div>


                <div className="mt-3 mb-3 relative ">
                  <input type="password" id="confirm_password" onChange={(event) => { setConfirmPassword(event.target.value) }} className="block border border-gray-300 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-white rounded-lg  appearance-none focus:outline focus:ring-0  peer" placeholder="" />
                  <label htmlFor="confirm_password" className="absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 start-1 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">Confirm Password</label>
                </div>
              </>
            }
            <div className='text-red-500 text-sm'>{errorMessage}</div>
            <div className='flex justify-center mt-3'>
              <button
                type="submit"
                onClick={register}
                className="flex justify-center items-center w-64 text-center font-semibold text-md py-1.5 rounded-md bg-_blue text-white hover:bg-green-dark focus:outline-none my-1"
              >
                <span className={"animate-spin rounded-full h-4 w-4 border-t-4 border-_white-50 border-solid mr-4 " + (!loading && ' hidden')}></span>
                Create Account</button>

            </div>
          </form>


        </div>
      </div>
    </div>
  )
}

export default SignIn
