
const FoodType = ({type}) => {
    let src = null
    switch (type) {
        case "veg":
            src = require("../../../assets/client/image/type/veg.png");
            break;
        case "nonVeg":
            src = require("../../../assets/client/image/type/non-veg.png");    
            break;
        case "jain":
            src = require("../../../assets/client/image/type/jain.png");    
            break;
    }

    return (
      <span>
        {src && 
        <img
          className='mr-2 w-4'
          src = {src}
          alt=''
          />
        } 
      </span>
    );
  };


export default FoodType;
  