import { useContext, useState } from "react";
import CartContext from "../../../context/CartContext";
import Dropdown from "../other/Dropdown";
import OtherStorage from "../../../storage/OtherStorage";
import UserStorage from "../../../storage/UserStorage";
import DeliveryForDropdown from "../other/DeliveryForDropdown";
import CartStorage from "../../../storage/CartStorage";
import { faCopy } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import upiScreenShotUploader from '../../../component/client/upiScreenShotUploader';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { Upload, } from 'antd';

import axios from "axios";
import { useNavigate } from "react-router";

const OrderSummary = () => {
  const { cart } = useContext(CartContext);
  const navigate = useNavigate()
  const qrImage = require("../../../assets/client/image/temp/qr.png");
  const deliveryCharge = 50;
  const [fileList, setFileList] = useState([]);
  const [isUploaded, setIsUploaded] = useState(false)

  const [showPayment, setShowPayment] = useState(false);
  const [loading, setLoading] = useState(false);
  const [refID, setRefID] = useState("");
  const [error, setError] = useState("");

  let alpha = -1;
  const [deliveryFor, setDeliveryFor] = useState(-1);
  const [pickUpPoint, setPickUpPoint] = useState(0);

  const handleFileChange = ({ fileList }) => {

  };


  const now = new Date()
  const slotTimeList = Array.from(OtherStorage.Instance.slotTiming, (time, index) => {
    const delTime = new Date();
    const median = time.slice(-2)
    let t = time.slice(0, -3).split(':')
    t[0] = parseInt(t[0])
    t[1] = parseInt(t[1])
    if (median === 'AM') {
      delTime.setHours(t[0])
      delTime.setMinutes(t[1])
    } else {
      delTime.setHours(t[0] === 12 ? 12 : t[0] + 12)
      delTime.setMinutes(t[1])
    }
    let remHr = delTime.getHours() - now.getHours()
    let remMin = delTime.getMinutes() - now.getMinutes()
    if (remMin < 0) {
      remMin += 60
      remHr -= 1
    }
    if (remHr > 0){
      if(alpha == -1){
        alpha = index;  
      }
      return `Today, ${time}`
    }
    return `Tomorrow, ${time}`
  });
    if(alpha == -1){
      alpha = 0;  
    }
  const [slotTiming, setSlotTiming] = useState(alpha);


  // paid: order.paid,
  const getDeliveryFor = () => {
    if (deliveryFor >= 0) {
      return { name: UserStorage.Instance.subUser[deliveryFor].name, phone_number: UserStorage.Instance.subUser[deliveryFor].phone_number, email: UserStorage.Instance.email }
    }
    else {
      return { name: UserStorage.Instance.name, phone_number: UserStorage.Instance.phone_number, email: UserStorage.Instance.email }
    }
  }

  const user_name = getDeliveryFor()
  const { uploadImage, removeImage } = upiScreenShotUploader({ setRefID, setIsUploaded,setFileList, user_name });
  const CheckAuth = () => {
    if (UserStorage.Instance.auth) {
      setShowPayment(true);
    }
    else {
      navigate('/login')
    }
  }
  const placeOrder = async () => {
    setLoading(true)
    if (refID !== '') {
      const orderSummary = {
        pick_up_point: OtherStorage.Instance.pickUpPoint[pickUpPoint],
        user_id: UserStorage.Instance.id,
        order_for: getDeliveryFor(),
        order_item: CartStorage.Instance.cartItems,
        order_total: CartStorage.Instance.totalAmount,
        slot_time: OtherStorage.Instance.slotTiming[slotTiming],
        city: "Hyderabad",
        order_time: "-",
        delivery_charge: deliveryCharge,
        item_count: CartStorage.Instance.itemCount,
        invoice_id: refID,
        paid: false,
        notified: false,
      }
      const response = await axios.post(process.env.REACT_APP_URL + 'order/addOrder', { order: orderSummary })
      CartStorage.Instance.DeleteCache()
      navigate('/orderPlaced')
    }
    else {
      setError('Reference ID not provided');
    }
    setLoading(false)
  }

  return (
    <div className='basis-5/12 h-fit top-40 p-8 rounded-md border shadow-md my-8 md:m-0'>
      <h2 className='md:text-xl text-l font-bold border-b pb-4'>Order Summary</h2>

      <div className='py-4 md:text-lg text-md space-y-4 border-b'>
        <div className='flex justify-between items-center font-medium'>
          <p className='font-normal'>Delivery For</p>
          <div><DeliveryForDropdown callbackFx={(i) => setDeliveryFor(i)} /></div>
        </div>
        <div className='flex justify-between items-center font-medium'>
          <p className='font-normal'>Pick-Up Point</p>
          <div><Dropdown list={OtherStorage.Instance.pickUpPoint} initialValue={0} callbackFx={(i) => setPickUpPoint(i)} /></div>
        </div>
        <div className='flex justify-between items-center font-medium'>
          <p className='font-normal'>Delivery Slot Time</p>
          <div><Dropdown list={slotTimeList} initialValue={slotTiming} callbackFx={(i) => setSlotTiming(i)} /></div>
        </div>
      </div>

      <div className='py-4 md:text-lg text-md space-y-4 border-b'>
        <div className='flex justify-between items-center font-semibold'>
          <p className='font-normal'>Price ({cart.itemCount} items)</p>
          <p>₹ {cart.totalAmount}</p>
        </div>
        <div className='flex justify-between items-center font-semibold'>
          <p className='font-normal'>Delivery charges</p>
          {deliveryCharge == 0 ?
            <p>FREE</p>
            :
            <p>₹ {deliveryCharge}</p>
          }
        </div>
      </div>

      <div className='py-4 border-b'>
        <div className='flex justify-between items-center font-bold lg:text-2xl md:text-xl sm:text-2xl'>
          <h1>Total Amount</h1>
          <h1 className='text-_blue'>
            ₹ {cart.totalAmount + deliveryCharge}
          </h1>
        </div>
      </div>

      {showPayment &&
        <div>
          <img
            src={qrImage}
            alt='qrcode'
            className='relative w-full aspect-square overflow-hidden object-cover block rounded-lg'
          />
          <p className='flex justify-center items-center pt-3 px-2 text-base text-gray-500'>
            Or Pay directly through UPI
          </p>
          <p className='flex justify-between pt-3 px-2 text-base text-gray-500'>
            9954953008260@paytm
            <span className="cursor-pointer text-_blue" onClick={() => navigator.clipboard.writeText('9954953008260@paytm')}><FontAwesomeIcon icon={faCopy} /></span>
          </p>
          <p className='flex justify-between pt-3 px-2 text-base text-gray-500'>
            lalit.raj9924@okaxis
            <span className="cursor-pointer text-_blue" onClick={() => navigator.clipboard.writeText('lalit.raj9924@okaxis')}><FontAwesomeIcon icon={faCopy} /></span>
          </p>
          <p className='flex justify-between pt-3 px-2 text-base text-gray-500'>
            sneko4college@kotak
            <span className="cursor-pointer text-_blue" onClick={() => navigator.clipboard.writeText('sneko4college@kotak')}><FontAwesomeIcon icon={faCopy} /></span>
          </p>
          <p className='mt-2 pt-2 px-2 text-base text-gray-500 border-t-2'>
            Upload Your Transaction Screen-Shot below.
          </p>
          <div className="mt-3 relative ">
            {isUploaded ? (
              <Upload
                action="/upload.do"
                listType="picture-card"
                fileList={fileList}
                onRemove={() => removeImage({ url: refID })}
              >

              </Upload>
            ) : (
              <Upload
                action="/upload.do"
                listType="picture-card"
                customRequest={uploadImage}
                fileList={fileList}
                onChange={handleFileChange}
              >
                <div>
                  <PlusOutlined />
                  <div style={{ marginTop: 8 }}>Upload</div>
                </div>
              </Upload>
            )}
          </div>
          <p className='px-2 text-sm text-red-500'>
            {error}
          </p>
        </div>
      }

      <button onClick={() => {
        if (showPayment) placeOrder(true);
        else CheckAuth();
      }}
        className='flex justify-center items-center w-full block mt-4 uppercase font-bold text-lg bg-_blue text-white text-center p-4 rounded-md'>
        <span className={"animate-spin rounded-full h-4 w-4 border-t-4 border-_white-50 border-solid mr-4 " + (!loading && 'hidden')}></span>
        {showPayment ? "Place order" : "Checkout"}
      </button>
    </div>
  );
};

export default OrderSummary;
